import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useCreditsQuery } from "../../graphql/generated/graphql";
import { WarningBox } from "../InfoBox";
import { CreditModalDecrease } from "./ModalDecrease";

export const ReturnCredits = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Settings Page" });
  const { t: tSuggestions } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  const [modalOpenedDecrease, setModalDecreaseOpened] = useState(false);
  const { data, loading, refetch } = useCreditsQuery();
  const hasCredits = !!data?.organization?.creditBalance.amount;
  const isPendingCreditWithdrawal = !!data?.organization?.pendingCreditWithdrawal;

  let warningBoxText: string;
  if (!hasCredits) {
    warningBoxText = tSuggestions("Return Credits NoCredits WarningBox");
  } else {
    warningBoxText = isPendingCreditWithdrawal
      ? tSuggestions("Return Credits PendingCreditWithdrawal WarningBox")
      : tSuggestions("Return Credits WarningBox");
  }

  return (
    <>
      {!loading && <WarningBox>{warningBoxText}</WarningBox>}
      {hasCredits && !loading && (
        <div style={{ margin: "10px 0" }}>
          <Button
            data-test-id="credit-onetime-decrease-button"
            disabled={!hasCredits || isPendingCreditWithdrawal}
            variant={"destructive"}
            onClick={() => setModalDecreaseOpened(true)}
          >
            {t("Return Credits Button")}
          </Button>
        </div>
      )}
      <CreditModalDecrease
        creditBalance={data?.organization?.creditBalance.amount}
        isOpen={modalOpenedDecrease}
        updateCreditAmount={refetch}
        onClose={() => {
          setModalDecreaseOpened(false);
        }}
      />
    </>
  );
};
