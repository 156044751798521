import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider, ServerError } from "@apollo/client";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  ContextPreview,
  ContextWithBackground,
  PlaPreview,
  PlaWithBackground,
  SearchPreview,
  SearchPreviewMobile,
  SearchWithBackground,
  YoutubePreview,
  YoutubeWithBackground,
} from "./elements/AdPreview";
import { ErrorBoundary } from "./elements/ErrorBoundary";
import { Icon, IconKinds } from "./elements/Icons";
import Loading from "./elements/Loading/Loading";
import { ModalWrapper } from "./elements/ModalWrapper";
import ScrollToTop from "./elements/ScrollToTop";
import {
  ImageSuggestionKindT,
  OrganizationNotReadyStepsT,
  ProductsCountStateT,
  SuggestionKindT,
  WizardStepT,
  useOrganizationQuery,
} from "./graphql/generated/graphql";
import { Error404Page } from "./pages/404Page";
import { Assets } from "./pages/Assets";
import { Dashboard } from "./pages/Dashboard";
import { EditImageAssets } from "./pages/EditImageAssets";
import { EditTextAssets } from "./pages/EditTextAssets";
import { History } from "./pages/History";
import { HistoryAuditLogs } from "./pages/HistoryAuditLogs";
import { NoProducts } from "./pages/NoProducts";
import { NotReady } from "./pages/NotReady";
import { ProductsPage } from "./pages/Products";
import { Settings } from "./pages/Settings";
import { Check } from "./pages/wizard/Check";
import { Finish } from "./pages/wizard/Finish";
import { FirstPage } from "./pages/wizard/FirstPage";
import { ImageSelection } from "./pages/wizard/ImageSelection";
import { SuggestionGenerator } from "./pages/wizard/SuggestionGenerator";
import { TextSelection } from "./pages/wizard/TextSelection";
import { useAppSettings } from "./providers/appSettingsProvider";
import { createClient } from "./providers/graphqlProvider";
import { isInIframe } from "./utils/isInIframe";

export type AuthorizationGuardPropsT = {
  currentUrl: string;
};

const IndexPage = ({ path }: { path?: string }) => {
  const { t } = useTranslation("translation", { keyPrefix: "Index Page" });
  const navigate = useNavigate();
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <div style={{ width: "390px", marginInline: "auto" }}>
        <div style={{ textAlign: "center" }}>
          <Icon className="icon-size-96" kind="delete-item" style={{ color: "#DB1B4E" }} />
        </div>
        {path && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              data-test-id="continue-with-less-products"
              variant="primary"
              onClick={() => navigate(path, { replace: true })}
            >
              {t("Button")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const HomePage = () => {
  const { apiToken } = useAppSettings();
  const { data } = useOrganizationQuery({});
  const [counter, setCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalLong, setShowModalLong] = useState(false);

  if (counter === 2) {
    throw new Error("Counter error - test");
  }

  return (
    <div>
      <h1>Hello Shoptet {data && data?.organization?.shoptetUrl}</h1>
      <Link to="/wizard">Start Wizard</Link>
      <br />
      <br />
      <Link to="/dashboard">Dashboard</Link>
      <br />
      <br />
      <Link to="/dashboard/products">Products</Link>
      <br />
      <br />
      <a href={`/admin?api_token=${apiToken}`}>Admin - Dashboard</a>
      <br />
      <br />
      <a href={`/admin/organizations?api_token=${apiToken}`}>Admin - Organization</a>
      <br />
      <br />
      <a href="/admin/sidekiq">SIDEKIQ</a>
      <br />
      <br />
      <ul>
        <li>
          <Link to="/wizard/">Step 1 - start</Link>
        </li>
        <li>
          <Link to="/wizard/suggestion-generator">Step 2 - suggestions</Link>
        </li>
        <li>
          <Link to="/wizard/image-selection">Step 3 - baner selection</Link>
        </li>
        <li>
          <Link to="/wizard/text-selection">Step 4 - text selection</Link>
        </li>
        <li>
          <Link to="/wizard/check">Step 5 - check</Link>
        </li>
        <li>
          <Link to="/wizard/finish">Step 6 - Finish</Link>
        </li>
      </ul>
      <br />
      <br />
      <Link to="/development/preview-ads">Showcase preview Ads</Link>
      {" | "}
      <Link to="/development/not-ready">Not ready test page</Link>
      {" | "}
      <Link to="/development/no-products">No products test page</Link>
      {" | "}
      <Link to="/development/icons">Icons list</Link>
      {" | "}
      <Button onClick={() => setShowModal(true)}>Show modal</Button>
      {" | "}
      <Button onClick={() => setShowModalLong(true)}>Show long modal</Button>
      {" | "}
      <Button data-test-id="test-error-boundary-button" onClick={() => setCounter((current: number) => current + 1)}>
        Increment counter + 1 (if {counter} == 2 it throws error)
      </Button>
      {" | "}
      <a href="https://shoptet-stout.vercel.app/" target="_blank">
        Storybook of Shoptet UI components
      </a>
      {" | "}
      <a href="https://dotidot.myshoptet.com/admin/design-guide/" target="_blank">
        Shoptet admin design guide
      </a>
      <ModalWrapper
        isOpen={showModal}
        primaryButton={{ onClick: () => alert("primaryButton action"), value: "Some primary action" }}
        secondaryButtons={[{ onClick: () => alert("secondaryButtons action"), value: "Some secondary action" }]}
        title="Some title"
        onClose={() => setShowModal(false)}
      >
        Foo Bar
      </ModalWrapper>
      <ModalWrapper
        isOpen={showModalLong}
        primaryButton={{ onClick: () => alert("primaryButton action"), value: "Some primary action" }}
        secondaryButtons={[{ onClick: () => alert("secondaryButtons action"), value: "Some secondary action" }]}
        title="Some title"
        onClose={() => setShowModalLong(false)}
      >
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
        Foo Bar
        <br />
      </ModalWrapper>
    </div>
  );
};

const PREVIEW_DATA = {
  logo: "https://dotidot.myshoptet.com/user/logos/shoptet-logo.png",
  headline: "Suunto Core Alu Black with super long name",
  description: "Some super description which is super long",
  imageUrl: "https://dotidot.myshoptet.com/user/shop/detail/1-1_suunto-core-alu-black.jpg?65116ea6",
  price: "CZK 1,234.43",
  domain: "dotidot.myshoptet.com",
  businessName: "Dotidot",
};
const PreviewAds = () => {
  return (
    <div>
      <Link to="/">Home</Link>

      <h2>Context Ad preview</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <ContextPreview {...PREVIEW_DATA} headline="Short headline" />
        <ContextPreview {...PREVIEW_DATA} description="Short description" headline="Short headline" />
        <ContextPreview {...PREVIEW_DATA} description="Short description" />
        <ContextPreview {...PREVIEW_DATA} />
      </div>

      <h2>Shopping Ad preview</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <PlaPreview {...PREVIEW_DATA} />
        <PlaPreview {...PREVIEW_DATA} price="CZK 1,000,123" />
        <PlaPreview {...PREVIEW_DATA} headline="Suunto Core Alu Black with super long name and super moore content" />
      </div>

      <h2>Youtube Ad preview</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <YoutubePreview {...PREVIEW_DATA} />
      </div>

      <h2>Search Ad preview Desktop</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <SearchPreview
          {...PREVIEW_DATA}
          descriptions={["All available", "All in stock"]}
          headlines={["sdfsafas", "Best prices", "Sale"]}
        />
        <SearchPreview
          {...PREVIEW_DATA}
          descriptions={["All available", "All in stock"]}
          headlines={["sdfsafas super long", "Best prices and super", "And super longss"]}
        />
      </div>
      <h2>Search Ad preview Mobile</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <SearchPreviewMobile
          {...PREVIEW_DATA}
          descriptions={["All available", "All in stock"]}
          headlines={["sdfsafas", "Best prices", "Sale"]}
        />
        <SearchPreviewMobile
          {...PREVIEW_DATA}
          descriptions={["All available", "All in stock"]}
          headlines={["sdfsafas super long", "Best prices and super", "And super longss"]}
        />
      </div>

      <br />
      <br />
      <h2>Context Ad preview With device</h2>
      <ContextWithBackground {...PREVIEW_DATA} />
      <br />
      <br />
      <h2>Shopping Ad preview With device</h2>
      <PlaWithBackground {...PREVIEW_DATA} />
      <br />
      <br />
      <h2>Search Ad preview With device</h2>
      <SearchWithBackground
        {...PREVIEW_DATA}
        descriptions={["popis", "All available"]}
        headlines={["Best prices", "Sale", "New items"]}
      />
      <br />
      <br />
      <h2>Youtube Ad preview With device</h2>
      <YoutubeWithBackground {...PREVIEW_DATA} />
    </div>
  );
};

const NotReadyTest = () => {
  return (
    <div>
      <Link to="/">Home</Link>

      <NotReady
        categoriesCount={50}
        notReadySteps={[OrganizationNotReadyStepsT.DesignT, OrganizationNotReadyStepsT.ProductsT]}
        productFlagsCount={9}
        productsCount={300}
        productsCountToSynchronize={1500}
      />
      <NotReady
        categoriesCount={50}
        notReadySteps={[OrganizationNotReadyStepsT.DesignT, OrganizationNotReadyStepsT.ProductsT]}
        productFlagsCount={9}
        productsCount={5500}
        productsCountToSynchronize={2000}
      />
      <NotReady
        categoriesCount={50}
        notReadySteps={[OrganizationNotReadyStepsT.DesignT, OrganizationNotReadyStepsT.ProductsT]}
        productFlagsCount={9}
        productsCount={0}
        productsCountToSynchronize={0}
      />
    </div>
  );
};

const NoProductsTest = () => {
  return (
    <div>
      <Link to="/">Home</Link>

      <NoProducts />
    </div>
  );
};

const IconsExamples = () => {
  return (
    <div>
      <Link to="/">Home</Link>
      <ul>
        {IconKinds.map((icon) => (
          <li key={icon}>
            <Icon kind={icon} />
            <pre style={{ display: "inline-block", marginLeft: "20px" }}>&lt;Icon kind="{icon}" /&gt;</pre>
          </li>
        ))}
      </ul>
    </div>
  );
};

const WIZARD_STEP_PATH = {
  [WizardStepT.FirstT]: "/wizard",
  [WizardStepT.SuggestionGeneratorT]: "/wizard/suggestion-generator",
  [WizardStepT.ImageSelectionT]: "/wizard/image-selection",
  [WizardStepT.TextSelectionT]: "/wizard/text-selection",
  [WizardStepT.CheckT]: "/wizard/check",
  [WizardStepT.FinishT]: "/wizard/finish",
  [WizardStepT.DashboardT]: "/dashboard",
};

const AuthorizationGuard = ({ currentUrl }: AuthorizationGuardPropsT) => {
  const navigate = useNavigate();
  const [alreadyNavigated, setAlreadyNavigated] = useState(false);

  const { data, error, loading, startPolling, stopPolling } = useOrganizationQuery({
    onCompleted(responseData) {
      if (responseData.organization?.notReadySteps && responseData.organization?.notReadySteps.length > 0) {
        startPolling(1000);
      } else {
        stopPolling();
        if (responseData.organization?.wizardStep && !alreadyNavigated) {
          navigate(WIZARD_STEP_PATH[responseData.organization?.wizardStep], { replace: true });
          setAlreadyNavigated(true);
        }
      }
    },
  });

  if (error) {
    return <div>Something went wrong: {(error?.networkError as ServerError)?.statusCode}</div>;
  }
  if (loading) {
    return <Loading />;
  }

  if (data?.organization?.notReadySteps && data.organization.notReadySteps.length > 0) {
    return (
      <NotReady
        categoriesCount={data?.organization?.categoriesCount || 0}
        notReadySteps={data?.organization?.notReadySteps}
        productFlagsCount={data?.organization?.productFlagsCount || 0}
        productsCount={data?.organization?.productsCount || 0}
        productsCountToSynchronize={data?.organization?.productsCountToSynchronize || 0}
      />
    );
  }

  if (data?.organization?.productsCountState === ProductsCountStateT.NoProductsT) {
    return <NoProducts />;
  }

  return (
    <div style={{ padding: isInIframe() ? 0 : "40px" }}>
      <ScrollToTop />
      <ErrorBoundary>
        <Routes>
          <Route path={currentUrl}>
            <Route
              element={
                <IndexPage path={data?.organization?.wizardStep && WIZARD_STEP_PATH[data.organization?.wizardStep]} />
              }
              index
            />
            {data?.env !== "production" && <Route element={<HomePage />} path="/dev/homepage" />}
            <Route path="/wizard">
              <Route element={<FirstPage />} index />
              <Route element={<SuggestionGenerator />} path="suggestion-generator" />
              <Route element={<ImageSelection />} path="image-selection" />
              <Route element={<TextSelection />} path="text-selection" />
              <Route element={<Check />} path="check" />
              <Route element={<Finish />} path="finish" />
            </Route>
            <Route path="dashboard">
              <Route element={<Dashboard />} index />
              <Route element={<ProductsPage />} path="products" />
            </Route>

            <Route element={<Settings />} path="settings" />

            <Route path="/assets">
              <Route element={<Assets />} index />
              <Route
                element={<EditTextAssets kind={SuggestionKindT.ShortHeadlineT} />}
                path={SuggestionKindT.ShortHeadlineT as string}
              />
              <Route
                element={<EditTextAssets kind={SuggestionKindT.LongHeadlineT} />}
                path={SuggestionKindT.LongHeadlineT as string}
              />
              <Route
                element={<EditTextAssets kind={SuggestionKindT.ShortDescriptionT} />}
                path={SuggestionKindT.ShortDescriptionT as string}
              />
              <Route
                element={<EditTextAssets kind={SuggestionKindT.LongDescriptionT} />}
                path={SuggestionKindT.LongDescriptionT as string}
              />
              <Route
                element={<EditImageAssets kind={ImageSuggestionKindT.SquareMarketingImageT} />}
                path={ImageSuggestionKindT.SquareMarketingImageT as string}
              />
              <Route
                element={<EditImageAssets kind={ImageSuggestionKindT.MarketingImageT} />}
                path={ImageSuggestionKindT.MarketingImageT as string}
              />
            </Route>
            <Route path="history">
              <Route element={<History />} index />
              <Route element={<HistoryAuditLogs />} path="audit-logs" />
            </Route>
            {data?.env !== "production" && (
              <>
                <Route element={<PreviewAds />} path="development/preview-ads" />
                <Route element={<NotReadyTest />} path="development/not-ready" />
                <Route element={<NoProductsTest />} path="development/no-products" />
                <Route element={<IconsExamples />} path="development/icons" />
                <Route element={<Error404Page />} path="*" />
              </>
            )}
          </Route>
        </Routes>
        {data?.env !== "production" && (
          <div style={{ textAlign: "right", opacity: 0.5, marginTop: "10px" }}>
            <Link data-test-id="link-development-home" to="/dev/homepage">
              DEVELOPMENT HOME
            </Link>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

function App() {
  const { apiToken, apiUri, currentUrl } = useAppSettings();

  return (
    <BrowserRouter basename="/app">
      <ApolloProvider client={createClient({ apiUri, apiToken })}>
        <AuthorizationGuard currentUrl={currentUrl} />
        <ToastContainer />
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
