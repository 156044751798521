import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useProductsQuery } from "../../graphql/generated/graphql";
import { formatMoney } from "../../i18n/formatNumbers";
import { PaginateNext, PaginatePrevious, PaginationContainer } from "../AdPreview/Pagination";
import { SmallLoader } from "../SmallLoader";

const limit = 10;

type ProductsPropsT = {
  gmcError?: string | null;
  gmcIssueId?: string | null;
  gmcWarning?: string | null;
};
export const Products = ({ gmcIssueId }: ProductsPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Products Page" });
  const [page, setPage] = useState<number>(1);

  const { data, loading } = useProductsQuery({
    variables: {
      limit,
      page,
      gmcIssueId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <SmallLoader />;
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("table header.image")}</TableHeaderCell>
            <TableHeaderCell>{t("table header.name")}</TableHeaderCell>
            <TableHeaderCell>
              <div className="text-right">{t("table header.price")}</div>
            </TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.organization?.products.map((product) => (
            <TableRow key={product.id} data-test-id={`table-row-product-${product.name}`}>
              <TableCell>
                <img alt={product.name} src={product.imageUrl} style={{ maxWidth: "100px", maxHeight: "100px" }} />
              </TableCell>
              <TableCell>
                <a href={product.shoptetProductDetailUrl} target="_top">
                  {product.name}
                </a>
              </TableCell>
              <TableCell>
                <div className="text-right">{formatMoney(product.price)}</div>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  <a href={product.shoptetProductDetailUrl} target="_top">
                    {t("show product")}
                  </a>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!(page === 1 && (data?.organization?.products.length || 0) < limit) && (
        <PaginationContainer>
          <PaginatePrevious disabled={page === 1} onClick={() => setPage((state) => Math.max(state - 1, 1))} />
          <PaginateNext
            disabled={(data?.organization?.products.length || 0) < limit}
            onClick={() => setPage((state) => state + 1)}
          />
        </PaginationContainer>
      )}
    </div>
  );
};
