import { useState } from "react";
import { Button, SystemMessage } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { CurrencyT, useCreditsQuery, useInitiatePaymentMutation } from "../../graphql/generated/graphql";
import { formatCurrencyUnit, formatMoney } from "../../i18n/formatNumbers";
import { getIframeUrl } from "../../utils/getIframeUrl";
import { isInIframe } from "../../utils/isInIframe";
import { Icon } from "../Icons";
import { InfoBox, WarningBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";
import { UpdateMonthlyBudget } from "../MonthlyBudget/update";
import NumberFieldWithUnit from "../NumberFieldWithUnit";
import { SmallLoader } from "../SmallLoader";

const TEST_NUMBER = /^\d+[\.,]?\d*$/;

const roundAmount = (amount: number) => Math.ceil(amount / 10) * 10;
const MINIMAL_PAYMENT_AMOUNT = 1000;
const MAXIMAL_PAYMENT_AMOUNT = 100000;

type CreditModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
};
export const CreditModal = ({ isOpen, onClose }: CreditModalPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Credits" });
  const [buyCustom, setBuyCustom] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    currency: CurrencyT.CzkT,
    creditAmount: "500",
  });
  const currency = state.currency;

  const creditAmountString = state.creditAmount.replace(/\s+/, "").replace(",", ".");
  const isCreditValidNumber = !!creditAmountString.match(TEST_NUMBER);
  const creditAmount = parseFloat(isCreditValidNumber ? creditAmountString : "0");

  const { data, loading } = useCreditsQuery();
  const isFirstPayment = data?.organization?.boughtCredits?.amount === 0;
  const monthlyBudget = data?.organization?.recommendedCreditIncreaseSmall?.amount || 0;
  const creditsBalance = data?.organization?.creditBalance?.amount || 0;
  const underLimit = isFirstPayment ? creditAmount < monthlyBudget : creditAmount < MINIMAL_PAYMENT_AMOUNT;
  const overLimit = creditAmount > MAXIMAL_PAYMENT_AMOUNT;
  const remainingOriginalDays = data?.organization?.remainingCreditDays || 0;

  const [initiatePayment, { error: mutationError, loading: loadingPayment }] = useInitiatePaymentMutation({
    onCompleted(response) {
      const urlToPay = response.initiatePayment?.payment?.urlToPay;

      if (urlToPay) {
        const link = document.createElement("a");
        link.href = urlToPay;
        if (isInIframe()) {
          link.target = "_top";
        }
        link.click();
      } else {
        setRedirecting(false);
        setError(true);
        // toast.error("Aktuálně nemůžeme nabít kredit. Zkuste to prosím později.");
      }
    },
  });

  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  const remainingCreditDays = Math.floor(
    ((data.organization.creditBalance.amount || 0) + creditAmount) / (data.organization.dailyCredits.amount || 1)
  );
  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      title="Dobití kreditu"
      disableScrollRestore
      positionTop
      onClose={onClose}
      onClosed={() => setBuyCustom(false)}
    >
      {error || mutationError ? (
        <div>
          <SystemMessage description={t("Unable to initiate payment")} level="error" />
          <Button onClick={onClose}>{t("Back")}</Button>
        </div>
      ) : isRedirecting ? (
        <div>
          <div style={{ justifyContent: "center", alignItems: "center", display: "flex", height: "300px" }}>
            <div style={{ textAlign: "center" }}>
              <Icon kind="loader" />
              <br />
              {t("Redirection to payment gaytway in progress")}
            </div>
          </div>
        </div>
      ) : (
        <>
          {!buyCustom && (
            <div>
              {isFirstPayment && <div style={{ maxWidth: "500px" }}>{t("Optimal credit recharge info")}</div>}
              {!isFirstPayment && (
                <InfoBox>
                  {t(
                    "The current status of your credit is CZK {{ creditsBalance }}. Your credit will last for another {{ days }} days.",
                    { creditsBalance, days: remainingOriginalDays }
                  )}
                </InfoBox>
              )}
              <div
                className="with-short-label"
                style={{ display: "flex", justifyContent: "center", margin: "2em 0 3em" }}
              >
                <UpdateMonthlyBudget />
              </div>
              <div
                style={{
                  border: "1px solid #E9E9E9",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minWidth: "267px",
                  position: "relative",
                  padding: "20px",
                  margin: "20px 0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    color: "#37373D",
                    background: "white",
                    padding: "0px 10px",
                  }}
                >
                  {t("Credits for 14 days")}
                </div>
                <div>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="Increase amount {{amount}} which will suffice for month"
                    t={t}
                    values={{
                      amount: formatMoney({
                        amount: roundAmount(data.organization?.recommendedCreditIncreaseSmall?.amount || 1000),
                        currency,
                      }),
                    }}
                  />
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    data-test-id="credit-increase-14days-button"
                    disabled={loadingPayment}
                    onClick={() => {
                      setError(false);
                      setRedirecting(true);
                      initiatePayment({
                        variables: {
                          amount: roundAmount(data.organization?.recommendedCreditIncreaseSmall?.amount || 3000),
                          currency,
                          returnUrl: getIframeUrl(),
                        },
                      });
                    }}
                  >
                    {t("Increase amount 1 month button") || t("Increase amount {{days}} button", { days: 14 })}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #E9E9E9",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minWidth: "267px",
                  position: "relative",
                  padding: "20px",
                  margin: "20px 0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    color: "#37373D",
                    background: "white",
                    padding: "0px 10px",
                  }}
                >
                  {t("Credits for 30 days")}
                </div>
                <div style={{ maxWidth: "350px" }}>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="Increase amount {{amount}} which will suffice for {{months}} months"
                    t={t}
                    values={{
                      amount: formatMoney({
                        amount: roundAmount(data.organization?.recommendedCreditIncreaseBig?.amount || 1000),
                        currency,
                      }),
                      months: 3,
                    }}
                  />
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    data-test-id="credit-increase-30days-button"
                    disabled={loadingPayment}
                    onClick={() => {
                      setError(false);
                      setRedirecting(true);
                      initiatePayment({
                        variables: {
                          amount: roundAmount(data.organization?.recommendedCreditIncreaseBig?.amount || 1000),
                          currency,
                          returnUrl: getIframeUrl(),
                        },
                      });
                    }}
                  >
                    {t("Increase amount 3 months button") || t("Increase amount {{days}} button", { days: 30 })}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #E9E9E9",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minWidth: "267px",
                  position: "relative",
                  padding: "20px",
                  margin: "20px 0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    color: "#37373D",
                    background: "white",
                    padding: "0px 10px",
                  }}
                >
                  {t("Credits custom amount")}
                </div>
                <div style={{ maxWidth: "330px" }}>
                  {isFirstPayment
                    ? t("Increase custom amount text - minimum monthly budget")
                    : t("Increase custom amount text")}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    data-test-id="credit-increase-custom-button"
                    disabled={loadingPayment}
                    variant="secondary"
                    onClick={() => setBuyCustom(true)}
                  >
                    {t("Increase custom amount button")}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {buyCustom && (
            <div className="v2form">
              {isFirstPayment && (
                <div style={{ marginTop: "-1.5em", marginBottom: "2em" }}>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="Enter the amount you wish to recharge. The minimum amount is the amount of one monthly budget (CZK {{amount}})."
                    t={t}
                    values={{
                      amount: formatMoney({
                        amount: roundAmount(monthlyBudget || 1000),
                        currency,
                      }),
                    }}
                  />
                </div>
              )}
              <div className="with-short-label" style={{ display: "flex", justifyContent: "center" }}>
                <NumberFieldWithUnit
                  name="credit_amount"
                  unit={formatCurrencyUnit(currency)}
                  value={state.creditAmount}
                  error={
                    (!isCreditValidNumber && t("not a valid number")) ||
                    (isCreditValidNumber && underLimit && t("under limit")) ||
                    (isCreditValidNumber &&
                      overLimit &&
                      t("over limit", { amount: formatMoney({ amount: MAXIMAL_PAYMENT_AMOUNT, currency }) })) ||
                    undefined
                  }
                  label={
                    isFirstPayment
                      ? t("Own amount lowercase")
                      : t("Custom credits label", {
                          amount: formatMoney({ amount: MINIMAL_PAYMENT_AMOUNT, currency }),
                        })
                  }
                  onChange={(event) => setState({ ...state, creditAmount: event.target.value })}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                {!underLimit && (
                  <InfoBox>
                    <Trans
                      components={{ strong: <strong /> }}
                      i18nKey="After credit increase {{amount}} your credit will {{days}} days"
                      t={t}
                      values={{ amount: formatMoney({ amount: creditAmount, currency }), days: remainingCreditDays }}
                    />
                  </InfoBox>
                )}
                {underLimit && isFirstPayment && (
                  <WarningBox>{t("The amount entered is lower than your current budget")}</WarningBox>
                )}
              </div>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!buyCustom && (
              <Button variant="secondary" onClick={onClose}>
                {t("Back")}
              </Button>
            )}
            {buyCustom && (
              <Button
                variant="secondary"
                onClick={() => {
                  setBuyCustom(false);
                }}
              >
                {t("Back")}
              </Button>
            )}
            {buyCustom && (
              <div style={{ marginLeft: "12px" }}>
                <Button
                  data-test-id="credit-increase-custom-save-button"
                  disabled={loadingPayment || !isCreditValidNumber || underLimit || overLimit}
                  onClick={() => {
                    setError(false);
                    setRedirecting(true);
                    initiatePayment({ variables: { amount: creditAmount, currency, returnUrl: getIframeUrl() } });
                  }}
                >
                  {t("Increase credits button")}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </ModalWrapper>
  );
};
