import React, { useState } from "react";
import { Button, SystemMessage } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import ReactCrop, { type Crop, PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import {
  ImageSuggestionKindT,
  UploadedImageFragmentT,
  useCreateImageAssetLineMutation,
} from "../../graphql/generated/graphql";
import { InfoBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";

const covertCropPxToPercent = (crop: Crop, uploadedImage: UploadedImageFragmentT): Crop => ({
  unit: "%",
  x: (crop.x / uploadedImage.width) * 100,
  y: (crop.y / uploadedImage.height) * 100,
  width: (crop.width / uploadedImage.width) * 100,
  height: (crop.height / uploadedImage.height) * 100,
});

const covertCropPercentToPx = (crop: Crop, uploadedImage: UploadedImageFragmentT): Crop => {
  const xCrop = crop.x < 0 ? 0 : crop.x;
  const yCrop = crop.y < 0 ? 0 : crop.y;

  return {
    unit: "px",
    x: Math.round((xCrop * uploadedImage.width) / 100),
    y: Math.round((yCrop * uploadedImage.height) / 100),
    width: Math.round((crop.width * uploadedImage.width) / 100),
    height: Math.round((crop.height * uploadedImage.height) / 100),
  };
};

const getDefaultCrop = (width: number, height: number, aspect: number): Crop => {
  let cropWidth = 0;
  let cropHeight = 0;

  if (aspect < 1) {
    // "tall" crop
    cropWidth = Math.min(height * aspect, width);
    cropHeight = cropWidth / aspect;
  } else {
    // "wide" or square crop
    cropHeight = Math.min(width / aspect, height);
    cropWidth = cropHeight * aspect;
  }

  return {
    unit: "px",
    x: Math.floor((width - cropWidth) / 2),
    y: Math.floor((height - cropHeight) / 2),
    width: cropWidth,
    height: cropHeight,
  };
};

type CropImagePropsT = {
  draft?: boolean;
  onClose: () => void;
  uploadedImage: UploadedImageFragmentT;
};

export const IMAGE_PROPERTIES = {
  [ImageSuggestionKindT.MarketingImageT]: { aspect: 1.91, minWidth: 600, minHeight: 314 },
  [ImageSuggestionKindT.SquareMarketingImageT]: { aspect: 1, minWidth: 300, minHeight: 300 },
  [ImageSuggestionKindT.PortraitMarketingImageT]: { aspect: 0.8, minWidth: 480, minHeight: 600 },
};

export const CropImage = ({ draft, onClose, uploadedImage }: CropImagePropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.UploadImage" });
  const { aspect, minHeight, minWidth } = IMAGE_PROPERTIES[uploadedImage.kind];

  const [crop, setCrop] = useState<Crop>(
    covertCropPxToPercent(getDefaultCrop(uploadedImage.width, uploadedImage.height, aspect), uploadedImage)
  );

  const setCropHandler = (ppc: PercentCrop) => {
    if (ppc.x < 0) {
      ppc.x = 0;
    }

    if (ppc.y < 0) {
      ppc.y = 0;
    }

    setCrop(ppc);
  };

  const pxCrop = covertCropPercentToPx(crop, uploadedImage);

  const [createImageAsset, { error: mutationError, loading }] = useCreateImageAssetLineMutation({
    variables: {
      draft,
      uploadedImageId: uploadedImage.id,
      cropX: pxCrop.x,
      cropY: pxCrop.y,
      cropWidth: pxCrop.width,
      cropHeight: pxCrop.height,
    },
    onCompleted: (response) => {
      if (response.createImageAssetLine?.assetLine?.id) {
        //toast.success("Vytvořen obrázek");
        onClose();
      } else {
        toast.error("Došlo k chybě při vytváření obrázku");
      }
    },
  });

  const tooSmall = minWidth > pxCrop.width || minHeight > pxCrop.height;
  const noSelection = pxCrop.width == 0 || pxCrop.height == 0;
  return (
    <ModalWrapper title={t("crop modal headline")} disableScrollRestore isOpen onClose={onClose}>
      <InfoBox>
        <strong>{t("crop modal info box text")}</strong>
        <ul style={{ marginBottom: 0 }}>
          <li>{t("crop modal info box list 1", { aspect: `${aspect}:1` })}</li>
          <li>{t("crop modal info box list 2")}</li>
        </ul>
      </InfoBox>

      <div style={{ marginTop: "30px" }}>
        <ReactCrop aspect={aspect} crop={crop} onChange={(cpx, ppc) => setCropHandler(ppc)}>
          <img src={uploadedImage.sourceImageUrl} style={{ userSelect: "none", maxHeight: "400px" }} />
        </ReactCrop>
      </div>
      <br />
      {mutationError && <SystemMessage description={t("there was a problem uploading image")} level="error" />}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="secondary" onClick={onClose}>
          {t("back")}
        </Button>
        <div>
          {noSelection && (
            <span style={{ marginRight: "10px", color: "#db1b4e" }}>{t("crop modal errors no selection")}</span>
          )}
          {tooSmall && !noSelection && (
            <span style={{ marginRight: "10px", color: "#db1b4e" }}>
              {t("crop modal errors small selection", { minWidth, minHeight })}
            </span>
          )}
          <Button disabled={tooSmall || noSelection || loading} onClick={() => createImageAsset()}>
            {loading ? t("uploading image in progress") : t("crop image button")}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};
