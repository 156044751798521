import { DateTime } from "luxon";

export const formatTime = (
  date: Date,
  options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
) => {
  if (date) {
    const date2 = new Date(date);
    return date2.toLocaleDateString("cs-CZ", options);
  }
  return "-";
};

export const formatDate = (
  date: Date | string,
  options: Intl.DateTimeFormatOptions = { year: "numeric", month: "numeric", day: "numeric" }
) => {
  if (date) {
    const date2 = new Date(date);
    return date2.toLocaleDateString("cs-CZ", options);
  }
  return "-";
};

export const timeFromStartToNow = (startDate: string | Date | number): string => {
  let start: DateTime;

  if (typeof startDate === "string") {
    start = DateTime.fromISO(startDate); // ISO String
  } else if (startDate instanceof Date) {
    start = DateTime.fromJSDate(startDate); // JS Date Object
  } else if (typeof startDate === "number") {
    start = DateTime.fromMillis(startDate); // Unix timestamp (milliseconds)
  } else {
    throw new Error("Invalid date format");
  }

  const now = DateTime.now();

  // Calculate the difference and format the output
  const diff = now.diff(start, ["years", "months", "days", "hours", "minutes", "seconds"]);

  const years = Math.floor(diff.years);
  const months = Math.floor(diff.months);
  const days = Math.floor(diff.days);
  const hours = Math.floor(diff.hours);
  const minutes = Math.floor(diff.minutes);
  const seconds = Math.floor(diff.seconds);

  let humanized = "";

  switch (true) {
    case years >= 1:
      humanized = years === 1 ? "about a year" : `${years} years`;
      break;
    case months >= 1:
      humanized = months === 1 ? "about a month" : `${months} months`;
      break;
    case days >= 1:
      humanized = days === 1 ? "about a day" : `${days} days`;
      break;
    case hours >= 1:
      humanized = hours === 1 ? "about an hour" : `${hours} hours`;
      break;
    case minutes >= 1:
      humanized = minutes === 1 ? "about a minute" : `${minutes} minutes`;
      break;
    case seconds < 10:
      humanized = "just now";
      break;
    default:
      humanized = `${seconds} seconds`;
  }

  return humanized;
};
