import React from "react";
import { FormRow, Input, NumberFieldProps } from "@shoptet/ui";
import classNames from "classnames";

type NumberFieldWithUnitPropsT = {
  addon?: React.ReactNode;
  note?: string;
  unit: string;
  wrapperClassName?: string;
} & NumberFieldProps;

const NumberFieldWithUnit: React.FC<NumberFieldWithUnitPropsT> = React.forwardRef<
  HTMLInputElement,
  NumberFieldWithUnitPropsT
>(({ addon, className, error, label, note, required, tooltip, unit, wrapperClassName, ...inputProps }, ref) => (
  <div className={wrapperClassName}>
    <FormRow label={label} required={required} tooltip={tooltip}>
      <div className="v2FormField__input">
        <span style={{ position: "relative" }}>
          <Input
            ref={ref}
            required={required}
            type="text"
            {...inputProps}
            className={classNames("numberField", className)}
            style={{ paddingRight: "40px" }}
          />
          {note && (
            <span
              style={{
                position: "absolute",
                right: "0",
                left: "0",
                top: "100%",
                paddingTop: "6px",
                color: "gray",
              }}
            >
              {note}
            </span>
          )}
          <span
            style={{
              position: "absolute",
              textAlign: "center",
              width: "35px",
              height: "24px",
              right: "1px",
              top: "-4px",
              borderLeft: "1px solid #CFCFCF",
              background: "#F3F3F3",
              padding: "4px",
            }}
          >
            {unit}
          </span>
        </span>
        {addon && addon}
        {error && <span className="v2FormField__error">{error}</span>}
      </div>
    </FormRow>
  </div>
));

NumberFieldWithUnit.displayName = "NumberFieldWithUnit";

export default NumberFieldWithUnit;
