import React, { useMemo, useState } from "react";
import { Button } from "@shoptet/ui";
import { CurrencyT, useAdminIncreaseCreditMutation } from "../../graphql/generated/graphql";
import { formatCurrencyUnit } from "../../i18n/formatNumbers";
import { WarningBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";
import NumberFieldWithUnit from "../NumberFieldWithUnit";

type CreditModalIncreasePropsT = {
  apiToken: string;
  onClose: () => void;
};
const CreditIncrease = ({ apiToken, onClose }: CreditModalIncreasePropsT) => {
  const [increaseAmount, setIncreaseAmount] = useState("");

  const [state] = useState({
    currency: CurrencyT.CzkT,
    creditAmount: "2000",
  });
  const currency = state.currency;

  const [increaseCredit, { loading: loadingPayment }] = useAdminIncreaseCreditMutation({
    onCompleted() {
      onClose();
    },
  });

  const isFormValid = useMemo(() => increaseAmount && parseFloat(increaseAmount || "0") <= 0, [increaseAmount]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncreaseAmount(event.target.value);
  };

  const handleSend = () => {
    increaseCredit({
      context: {
        headers: {
          Authorization: `ApiToken ${apiToken}`,
        },
      },
      variables: {
        amount: parseFloat(increaseAmount || "0"),
        currency,
      },
    });
  };

  return (
    <div>
      <div style={{ marginTop: "1em", marginBottom: "4em", display: "flex" }}>
        <NumberFieldWithUnit
          label="Zadejte částku kreditu, kterou chcete navýšit jako demo peníze"
          min={0}
          name="credit_amount"
          unit={formatCurrencyUnit(currency)}
          value={increaseAmount}
          onChange={handleChange}
        />
      </div>
      {isFormValid && <WarningBox>Zadaná částka musí být větší jak 0.</WarningBox>}
      <Button disabled={!!isFormValid} variant={"action"} onClick={handleSend}>
        {loadingPayment ? "Loading" : "Přidat kredity"}
      </Button>
    </div>
  );
};

export const CreditModalIncrease = (props: { apiToken: string }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div style={{ marginBottom: "5px" }}>
        <Button onClick={() => setOpen(true)}>+</Button>
      </div>

      <ModalWrapper isOpen={isOpen} title="Increasse demo credits" onClose={() => setOpen(false)}>
        {isOpen && <CreditIncrease {...props} onClose={() => setOpen(false)} />}
      </ModalWrapper>
    </>
  );
};
