import React, { useState } from "react";
import { LineChart } from "@shoptet/chart";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { MoneyFragmentT, useMonthSummaryStatsQuery, usePerformanceStatsQuery } from "../../graphql/generated/graphql";
import { formatDate } from "../../i18n/formatDate";
import { formatFloat, formatInteger, formatMoney } from "../../i18n/formatNumbers";
import { GraphTab, GraphTabWrapper } from "../../ui/GraphTabs";
import { StateTab, TabWrapper } from "../../ui/Tabs";
import { SmallLoader } from "../SmallLoader";

enum DateRange {
  THIS_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  ALL_TIME,
}

const DATE_RANGES = {
  [DateRange.THIS_WEEK]: {
    dateFrom: DateTime.utc().minus({ days: 1 }).startOf("week").toISO(),
    dateTo: DateTime.local().minus({ days: 1 }).toISO(),
  },
  [DateRange.THIS_MONTH]: {
    dateFrom: DateTime.utc().minus({ days: 1 }).startOf("month").toISO(),
    dateTo: DateTime.local().minus({ days: 1 }).toISO(),
  },
  [DateRange.LAST_MONTH]: {
    dateFrom: DateTime.utc().minus({ months: 1 }).startOf("month").toISO(),
    dateTo: DateTime.utc().minus({ months: 1 }).endOf("month").toISO(),
  },
  [DateRange.THIS_QUARTER]: {
    dateFrom: DateTime.utc().minus({ days: 1 }).startOf("quarter").toISO(),
    dateTo: DateTime.local().minus({ days: 1 }).toISO(),
  },
  [DateRange.THIS_YEAR]: {
    dateFrom: DateTime.utc().minus({ days: 1 }).startOf("year").toISO(),
    dateTo: DateTime.local().minus({ days: 1 }).toISO(),
  },
  [DateRange.ALL_TIME]: {
    dateFrom: null,
    dateTo: DateTime.local().minus({ days: 1 }).toISO(),
  },
};

enum Metrics {
  AVERAGE_CONVERSION_VALUE = "averageConversionValue",
  CLICKS = "clicks",
  CONVERSIONS = "conversions",
  CONVERSION_VALUE = "conversionValue",
  CREDIT = "credit",
  IMPRESSIONS = "impressions",
}

const TRANSLATION_KEYS = {
  [Metrics.IMPRESSIONS]: "Metric Impressions",
  [Metrics.CLICKS]: "Metric Clicks",
  [Metrics.CONVERSIONS]: "Metric Conversions",
  [Metrics.CONVERSION_VALUE]: "Metric Conversion Value",
  [Metrics.AVERAGE_CONVERSION_VALUE]: "Metric Average Conversion Value",
  [Metrics.CREDIT]: "Metric Credit",
};

export const PerformanceStats = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Stats" });
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.THIS_MONTH);
  const [metric, setMetric] = useState<Metrics>(Metrics.IMPRESSIONS);
  const { data, loading } = usePerformanceStatsQuery({ variables: DATE_RANGES[dateRange] });
  const { data: summaryStats } = useMonthSummaryStatsQuery({
    variables: {
      thisMonthDateFrom: DATE_RANGES[DateRange.THIS_MONTH].dateFrom,
      thisMonthDateTo: DATE_RANGES[DateRange.THIS_MONTH].dateTo,
      lastMonthDateFrom: DATE_RANGES[DateRange.LAST_MONTH].dateFrom,
      lastMonthDateTo: DATE_RANGES[DateRange.LAST_MONTH].dateTo,
    },
  });

  const format = (value?: null | number | MoneyFragmentT): string => {
    if (typeof value === "number") {
      if (metric === Metrics.IMPRESSIONS || metric === Metrics.CLICKS) {
        return formatInteger(value);
      }
      return formatFloat(value);
    } else if (typeof value?.amount === "number") {
      return formatMoney(value);
    }
    return "-";
  };

  const formatForGraph = (value?: null | number | MoneyFragmentT): number => {
    if (typeof value === "number") {
      return value;
    } else if (typeof value?.amount === "number") {
      return value.amount;
    }
    return 0;
  };

  const performanceStats =
    (data?.organization?.performanceStats?.length || 0) > 0 ? data?.organization?.performanceStats : null;
  return (
    <div>
      <TabWrapper>
        <StateTab currentPage={metric} page={Metrics.IMPRESSIONS} setPage={() => setMetric(Metrics.IMPRESSIONS)}>
          {t(TRANSLATION_KEYS[Metrics.IMPRESSIONS])}
        </StateTab>
        <StateTab currentPage={metric} page={Metrics.CLICKS} setPage={() => setMetric(Metrics.CLICKS)}>
          {t(TRANSLATION_KEYS[Metrics.CLICKS])}
        </StateTab>
        <StateTab currentPage={metric} page={Metrics.CONVERSIONS} setPage={() => setMetric(Metrics.CONVERSIONS)}>
          {t(TRANSLATION_KEYS[Metrics.CONVERSIONS])}
        </StateTab>
        <StateTab
          currentPage={metric}
          page={Metrics.CONVERSION_VALUE}
          setPage={() => setMetric(Metrics.CONVERSION_VALUE)}
        >
          {t(TRANSLATION_KEYS[Metrics.CONVERSION_VALUE])}
        </StateTab>
        <StateTab
          currentPage={metric}
          page={Metrics.AVERAGE_CONVERSION_VALUE}
          setPage={() => setMetric(Metrics.AVERAGE_CONVERSION_VALUE)}
        >
          {t(TRANSLATION_KEYS[Metrics.AVERAGE_CONVERSION_VALUE])}
        </StateTab>
        <StateTab currentPage={metric} page={Metrics.CREDIT} setPage={() => setMetric(Metrics.CREDIT)}>
          {t(TRANSLATION_KEYS[Metrics.CREDIT])}
        </StateTab>
      </TabWrapper>
      <GraphTabWrapper tabsCount={5}>
        <GraphTab
          active={dateRange === DateRange.THIS_MONTH}
          additional={t(TRANSLATION_KEYS[metric])}
          header={t("Date Range this month")}
          main={format(summaryStats?.organization?.thisMonthStats[metric]) || "-"}
          onClick={() => setDateRange(DateRange.THIS_MONTH)}
        />
        <GraphTab
          active={dateRange === DateRange.LAST_MONTH}
          additional={t(TRANSLATION_KEYS[metric])}
          header={t("Date Range last month")}
          main={format(summaryStats?.organization?.lastMonthStats[metric]) || "-"}
          onClick={() => setDateRange(DateRange.LAST_MONTH)}
        />
        <GraphTab
          active={dateRange === DateRange.ALL_TIME}
          additional={t(TRANSLATION_KEYS[metric])}
          header={t("Date Range all time")}
          main={format(summaryStats?.organization?.summaryStats[metric]) || "-"}
          onClick={() => setDateRange(DateRange.ALL_TIME)}
        />
      </GraphTabWrapper>
      {loading || !data?.organization?.performanceStats ? (
        <SmallLoader />
      ) : (
        <LineChart
          height="300px"
          theme="orange"
          data={{
            labels: (
              performanceStats || [{ date: DATE_RANGES[dateRange].dateFrom }, { date: DATE_RANGES[dateRange].dateTo }]
            ).map((row) => formatDate(row.date)),
            datasets: [
              {
                label: t(TRANSLATION_KEYS[metric]),
                data: (performanceStats || [{ [metric]: null }, { [metric]: null }]).map((row) =>
                  formatForGraph(row[metric])
                ),
                borderColor: "rgb(255, 174, 0)",
                backgroundColor: "rgba(0,0,0,0)",
              },
            ],
          }}
          legend
        />
      )}
    </div>
  );
};
