import React from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdPreview } from "../../elements/AdPreview";
import { BackIcon, NextIcon } from "../../elements/Icons";
import { Preview as ImageAssetLinesPreview } from "../../elements/ImageAssetLines/Preview";
import { InfoBox } from "../../elements/InfoBox";
import { UpdateMonthlyBudget } from "../../elements/MonthlyBudget/update";
import { ProgressStepper, StepsT } from "../../elements/ProgressStepper";
import { SmallLoader } from "../../elements/SmallLoader";
import { Preview as TextAssetLinesPreview } from "../../elements/TextAssetLines/Preview";
import {
  ImageSuggestionKindT,
  SuggestionKindT,
  WizardStepT,
  useActiveImagesAssetLinesQuery,
  useActiveTextsAssetLinesQuery,
  useOrganizationDataQuery,
  useProductExclusionQuery,
} from "../../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

const calculatePercent = (count: number, total: number) => Math.round(total > 0 ? (count / total) * 100 : 0);

export const Check = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Check Page" });
  useSetWizardStepEffect({ wizardStep: WizardStepT.CheckT });

  const navigate = useNavigate();

  const { data: organizationData, loading: loadingProductsAndDesign } = useOrganizationDataQuery();
  const { data: productExclusionData, loading: loadingProductFlags } = useProductExclusionQuery();
  const { data: dataActiveImageAssetLines, loading: loadingImages } = useActiveImagesAssetLinesQuery({
    fetchPolicy: "network-only",
  });
  const { data: dataActiveTextAssetLines, loading: loadingTexts } = useActiveTextsAssetLinesQuery({
    fetchPolicy: "network-only",
  });

  const excludedProductFlag = productExclusionData?.organization?.productFlags.find(
    (productFlag) => productFlag.excluded
  );
  const excludedCategories = (productExclusionData?.organization?.categories || []).filter(
    (category) => category.excluded
  );
  const excludedCategoryGuids = excludedCategories.map((category) => category.guid);
  const excludedCategoriesOnlyParents = excludedCategories.filter(
    (category) => excludedCategoryGuids.indexOf(category.parentGuid) === -1
  );

  const productsCount = productExclusionData?.organization?.selectableProductsCount || 0;
  const productsPercent = calculatePercent(productsCount, productExclusionData?.organization?.productsCount || 0);

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <ProgressStepper currentStep={StepsT.STEP_CHECK} />
      <InfoBox>{t("InfoBox")}</InfoBox>
      <h2>{t("Products Headline")}</h2>
      <div
        style={{
          border: "1px solid #E9E9E9",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "267px",
          position: "relative",
          padding: "20px",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            color: "#37373D",
            background: "white",
            padding: "0px 10px",
          }}
        >
          {t("Products BoxLabel")}
        </div>
        {loadingProductFlags ? (
          <SmallLoader />
        ) : (
          <>
            <span>
              <Trans
                components={{ strong: <strong style={{ color: "#14B1EF" }} /> }}
                i18nKey="Products Text"
                t={t}
                values={{ productsCount, productsPercent }}
              />
              {excludedProductFlag && (
                <>
                  <br />
                  <Trans
                    components={{ strong: <strong style={{ color: "#14B1EF" }} /> }}
                    i18nKey="Products without flag"
                    t={t}
                    values={{ flag: excludedProductFlag.name }}
                  />
                </>
              )}
              {excludedCategoriesOnlyParents.length > 0 && (
                <>
                  <br />
                  <Trans
                    components={{ strong: <strong style={{ color: "#14B1EF" }} /> }}
                    i18nKey="Products in categories"
                    t={t}
                    values={{ categories: excludedCategoriesOnlyParents.map((category) => category.path).join(", ") }}
                  />
                </>
              )}
            </span>
          </>
        )}
      </div>
      <h2>{t("Budget Headline")}</h2>
      <div style={{ margin: "40px 0" }}>
        <UpdateMonthlyBudget />
      </div>
      <div style={{ background: "#F8F8F8", padding: "10px", margin: "20px 0" }}>
        <h2>{t("Preview Headline")}</h2>
        {loadingImages || loadingTexts || loadingProductsAndDesign ? (
          <SmallLoader />
        ) : (
          <AdPreview
            activeImagesAssetLines={dataActiveImageAssetLines?.organization?.activeImagesAssetLines || []}
            activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
            organization={organizationData?.organization}
          />
        )}
      </div>
      <h2>{t("SquareMarketingImage")}</h2>
      {loadingImages && (
        <div>
          <SmallLoader />
        </div>
      )}
      <ImageAssetLinesPreview
        activeImagesAssetLines={dataActiveImageAssetLines?.organization?.activeImagesAssetLines || []}
        kind={ImageSuggestionKindT.SquareMarketingImageT}
      />
      <h2>{t("MarketingImage")}</h2>
      {loadingImages && (
        <div>
          <SmallLoader />
        </div>
      )}
      <ImageAssetLinesPreview
        activeImagesAssetLines={dataActiveImageAssetLines?.organization?.activeImagesAssetLines || []}
        kind={ImageSuggestionKindT.MarketingImageT}
      />
      <h2>{t("ShortHeadline")}</h2>
      {loadingTexts && (
        <div>
          <SmallLoader />
        </div>
      )}
      <TextAssetLinesPreview
        activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
        kind={SuggestionKindT.ShortHeadlineT}
      />
      <h2>{t("LongHeadline")}</h2>
      {loadingTexts && (
        <div>
          <SmallLoader />
        </div>
      )}
      <TextAssetLinesPreview
        activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
        kind={SuggestionKindT.LongHeadlineT}
      />
      <h2>{t("ShortDescription")}</h2>
      {loadingTexts && (
        <div>
          <SmallLoader />
        </div>
      )}
      <TextAssetLinesPreview
        activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
        kind={SuggestionKindT.ShortDescriptionT}
      />
      <h2>{t("LongDescription")}</h2>
      {loadingTexts && (
        <div>
          <SmallLoader />
        </div>
      )}
      <TextAssetLinesPreview
        activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
        kind={SuggestionKindT.ShortDescriptionT}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginRight: "10px" }}>
          <Button data-test-id="edit-assets" variant="secondary" onClick={() => navigate("/wizard/image-selection")}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <BackIcon />
              <span>&nbsp; {t("Back to edit")}</span>
            </span>
          </Button>
        </div>

        <Button data-test-id="continue-to-next-step" onClick={() => navigate("/wizard/finish")}>
          <span style={{ display: "flex", alignItems: "center" }}>
            {t("Start campaign")}
            &nbsp;
            <NextIcon />
          </span>
        </Button>
      </div>
    </div>
  );
};
