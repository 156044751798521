import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GmcIssueSeverityT, useGmcIssuesQuery } from "../../graphql/generated/graphql";
import { Icon } from "../Icons";
import { InfoBox } from "../InfoBox";
import { SmallLoader } from "../SmallLoader";

const calculatePercent = (count: number, total: number) => (total > 0 ? (count / total) * 100 : 0);

type TranslateFnType = ReturnType<typeof useTranslation>["t"];

const NOT_TRANSLATED = "NOT_TRANSLATED";

export const formatIssueName = (trans: TranslateFnType, name: string) => {
  const translatedText = trans(`attribute-message.${name}.headline`, { defaultValue: NOT_TRANSLATED });

  return translatedText === NOT_TRANSLATED ? "" : translatedText;
};

export const formatIssueDescription = (trans: TranslateFnType, name: string) => {
  const translatedText = trans(`attribute-message.${name}.description`, { defaultValue: NOT_TRANSLATED });

  return translatedText === NOT_TRANSLATED || translatedText.length === 0
    ? formatIssueName(trans, name)
    : translatedText;
};

export const GmcIssues = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.GMCIssues" });
  const { data, loading } = useGmcIssuesQuery();

  if (loading) {
    return <SmallLoader />;
  }
  const gmcIssues = data?.organization?.gmcIssues?.filter((issue) => formatIssueName(t, issue.name).length > 0) || [];

  if (gmcIssues.length === 0) {
    return <InfoBox data-test-id="gmc-issues-no-warning">{t("No Issues Info Box")}</InfoBox>;
  }

  return (
    <div>
      <InfoBox>
        {t("InfoBox first line")}
        <ul>
          <li>
            <Trans components={{ span: <span style={{ color: "#db1b4e" }} /> }} i18nKey="Info box error line" t={t} />
          </li>
          <li>
            <Trans components={{ span: <span style={{ color: "#FFAE00" }} /> }} i18nKey="Info box warning line" t={t} />
          </li>
        </ul>
      </InfoBox>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("table header.issue severity")}</TableHeaderCell>
            <TableHeaderCell>{t("table header.issue text")}</TableHeaderCell>
            <TableHeaderCell>
              <div className="text-right">{t("table header.product count")}</div>
            </TableHeaderCell>
            <TableHeaderCell>
              <div className="text-right">{t("table header.product percent")}</div>
            </TableHeaderCell>
            {/* <TableHeaderCell>{t("table header.action")}</TableHeaderCell> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {gmcIssues.map((issue) => {
            const color = issue.severity === GmcIssueSeverityT.ErrorT ? "#db1b4e" : "#FFAE00";
            const query = issue.severity === GmcIssueSeverityT.ErrorT ? "gmc_error" : "gmc_warning";

            return (
              <TableRow key={issue.name} data-test-id={`table-row-${issue.name}`}>
                <TableCell>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                    <Icon
                      kind={issue.severity === GmcIssueSeverityT.ErrorT ? "delete-item" : "warning"}
                      className={
                        issue.severity === GmcIssueSeverityT.ErrorT
                          ? "shoptet-icon-color-error"
                          : "shoptet-icon-color-warning"
                      }
                    />
                    <div>{formatIssueName(t, issue.name)}</div>
                  </div>
                </TableCell>
                <TableCell>
                  <span style={{ color }}>
                    {issue.severity === GmcIssueSeverityT.ErrorT ? t("error issue") : t("warning issue")}
                  </span>
                </TableCell>
                <TableCell>
                  <div className="text-right">
                    {issue.count}{" "}
                    <Link to={`/dashboard/products?${query}=${issue.name}&gmc_issue_id=${issue.id}`}>
                      ({t("show products")})
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-right">
                    {data?.organization?.validInStockProductsCount && issue.count
                      ? `${Math.round(calculatePercent(issue.count, data.organization?.validInStockProductsCount))}%`
                      : ""}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
