export const IconKinds = [
  "accomplish-item",
  "add-home-item",
  "add-item",
  "ajax-remove-item",
  "attachment",
  "bool-property",
  "close-item",
  "create-invoice",
  "delete-item",
  "delete-item-big",
  "disable-property",
  "disabled",
  "disabled-item",
  "enable-property",
  "export-item-to-pdf",
  "external-link",
  "external-link-grey",
  "folder",
  "folder-opened",
  "fullscreen",
  "goto-item",
  "info-item",
  "inline",
  "invoice",
  "link",
  "loader",
  "lock",
  "loop",
  "mark-to-remove",
  "menu",
  "move-item",
  "move-item",
  "mute-item",
  "negative",
  "negative",
  "neutral",
  "positive",
  "preview-icon",
  "print-item",
  "question",
  "remark-item",
  "remove-item",
  "remove-line",
  "reply-item",
  "scp-connect",
  "search",
  "set-default-item",
  "shoptet-icon",
  "split-item",
  "text-tooltip",
  "tiny",
  "toggle-attention",
  "toggle-visibility",
  "update",
  "warning",
  "wheel",
] as const;
type IconKindT = typeof IconKinds[number];

type IconPropsT = {
  kind: IconKindT;
} & React.HTMLProps<HTMLSpanElement>;

type SvgIconPropsT = {
  isBlack?: boolean;
};

export const Icon = ({ kind, ...rest }: IconPropsT) => (
  <span {...rest} className={`shoptet-icon ${kind} ${rest.className}`} />
);

export const CheckmarkIcon = () => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.311 5.02278C12.071 4.78278 11.761 4.65728 11.448 4.64678C11.1055 4.63478 10.759 4.76028 10.497 5.02278L6.86197 8.66178L5.50297 7.29478C5.24047 7.03228 4.89397 6.90678 4.55097 6.91878C4.23797 6.92978 3.92847 7.05528 3.68897 7.29478C3.41997 7.56428 3.29497 7.92228 3.31447 8.27428C3.33147 8.57878 3.45597 8.87778 3.68897 9.11128L6.39997 11.8253C6.52947 11.9548 6.69697 12.0203 6.86397 12.0213C7.03347 12.0223 7.20247 11.9568 7.33047 11.8253L12.311 6.83928C12.5595 6.59028 12.685 6.26578 12.6875 5.94078C12.69 5.60928 12.5645 5.27678 12.311 5.02328V5.02278Z"
      fill="white"
    />
  </svg>
);

export const NextIcon = ({ isBlack }: SvgIconPropsT) => (
  <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.19354 3.1333C5.32704 3.1333 5.46004 3.1868 5.56704 3.2868L11.1805 8.7733C11.2955 8.8848 11.3465 9.0353 11.3335 9.1818C11.323 9.2983 11.272 9.4118 11.1805 9.5003L5.56704 14.9868C5.46054 15.0898 5.32004 15.1398 5.18104 15.1368C5.05054 15.1338 4.92054 15.0838 4.82054 14.9868C4.71604 14.8858 4.66454 14.7523 4.66554 14.6193C4.66654 14.4888 4.71854 14.3593 4.82054 14.2603L10.0605 9.1338L4.82054 4.0138C4.69604 3.8933 4.64654 3.7268 4.67204 3.5698C4.68904 3.4663 4.73854 3.3668 4.82054 3.2873C4.92704 3.1873 5.06054 3.1338 5.19404 3.1338L5.19354 3.1333Z"
      fill={isBlack ? "#000000" : "#ffffff"}
    />
  </svg>
);

export const PlusIcon = () => (
  <svg fill="none" height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1665 8.4663H9.16649V4.4663C9.16649 4.0998 8.86649 3.7998 8.49999 3.7998C8.13349 3.7998 7.83349 4.0998 7.83349 4.4663V8.4663H3.83349C3.46699 8.4663 3.16699 8.7663 3.16699 9.1328C3.16699 9.4993 3.46699 9.7993 3.83349 9.7993H7.83349V13.7993C7.83349 14.1658 8.13349 14.4658 8.49999 14.4658C8.86649 14.4658 9.16649 14.1658 9.16649 13.7993V9.7993H13.1665C13.533 9.7993 13.833 9.4993 13.833 9.1328C13.833 8.7663 13.533 8.4663 13.1665 8.4663Z"
      fill="white"
    />
  </svg>
);

export const ChevronUpIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2296 16.5C20.0294 16.5 19.8396 16.4198 19.6799 16.2698L11.9999 8.40975L4.31989 16.2698C4.17439 16.4205 3.98389 16.4978 3.79189 16.5023C3.58864 16.5068 3.38464 16.4295 3.23014 16.2698C3.08464 16.1198 3.01039 15.9255 3.00514 15.729C2.99989 15.5198 3.07489 15.3098 3.23014 15.1493L11.4599 6.729C11.6189 6.56476 11.8311 6.48751 12.0404 6.49726C12.2256 6.50626 12.4094 6.58351 12.5504 6.72901L20.7704 15.1493C20.9301 15.3143 21.0051 15.5333 20.9946 15.7485C20.9856 15.9375 20.9106 16.1243 20.7704 16.269C20.6204 16.419 20.4306 16.4993 20.2304 16.4993L20.2296 16.5Z"
      fill="#14B1EF"
    />
  </svg>
);

export const ChevronDownIcon = () => (
  <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.77036 8.2998C3.97061 8.2998 4.16036 8.38005 4.32011 8.53005L12.0001 16.39L19.6801 8.53005C19.8256 8.3793 20.0161 8.30205 20.2081 8.29755C20.4114 8.29305 20.6154 8.3703 20.7699 8.53005C20.9154 8.68005 20.9896 8.8743 20.9949 9.0708C21.0001 9.28005 20.9251 9.49005 20.7699 9.65055L12.5401 18.0708C12.3811 18.235 12.1689 18.3123 11.9596 18.3025C11.7744 18.2935 11.5906 18.2163 11.4496 18.0708L3.22961 9.65055C3.06986 9.48555 2.99486 9.26655 3.00536 9.0513C3.01436 8.8623 3.08936 8.67555 3.22961 8.5308C3.37961 8.3808 3.56936 8.30055 3.76961 8.30055L3.77036 8.2998Z"
      fill="#14B1EF"
    />
  </svg>
);

export const BackIcon = () => (
  <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8061 15.1334C10.6726 15.1334 10.5396 15.0799 10.4326 14.9799L4.81911 9.49337C4.72211 9.39937 4.67061 9.27787 4.66461 9.15437C4.65811 9.01487 4.70911 8.87287 4.81911 8.76687L10.4326 3.28037C10.5356 3.18087 10.6701 3.13087 10.8046 3.13037C10.9401 3.12987 11.0756 3.17987 11.1796 3.28037C11.2836 3.38087 11.3346 3.51188 11.3346 3.64388C11.3346 3.77587 11.2831 3.90737 11.1796 4.00737L5.93961 9.13387L11.1796 14.2539C11.2946 14.3649 11.3456 14.5154 11.3326 14.6614C11.3226 14.7779 11.2711 14.8919 11.1796 14.9804C11.0731 15.0804 10.9396 15.1334 10.8061 15.1334Z"
      fill="#37373D"
    />
  </svg>
);

export const DisabledIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#DB1B4E" height="20" rx="10" width="20" />
    <path
      d="M10.6279 10.2891L14.4819 6.43513C14.5602 6.35681 14.603 6.25643 14.6104 6.15388C14.6195 6.02926 14.5766 5.90119 14.4819 5.80643C14.3914 5.716 14.2707 5.67273 14.1513 5.67705C14.0427 5.68095 13.9354 5.72422 13.8527 5.80643L9.99876 9.66042L6.14477 5.80643C6.06212 5.72378 5.95438 5.68051 5.84578 5.67705C5.72679 5.67316 5.6065 5.716 5.51606 5.80643C5.4226 5.89989 5.37933 6.0258 5.38712 6.14869C5.39361 6.25297 5.43645 6.35595 5.51606 6.43513L9.37005 10.2891L5.51606 14.1431C5.42953 14.2296 5.38626 14.3434 5.38626 14.4577C5.38626 14.5719 5.42953 14.6857 5.51606 14.7722C5.6026 14.8588 5.71813 14.9051 5.8276 14.9051C5.94313 14.9051 6.05823 14.8588 6.14477 14.7722L9.99876 10.9183L13.8527 14.7722C13.9393 14.8588 14.0548 14.9051 14.1699 14.9051C14.285 14.9051 14.4005 14.8588 14.4871 14.7722C14.555 14.7043 14.5961 14.6199 14.6108 14.5317C14.6338 14.3945 14.5922 14.2487 14.4871 14.1435L10.6275 10.2896L10.6279 10.2891Z"
      fill="white"
    />
  </svg>
);
