import React, { useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Tooltip } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useCreditTransactionsQuery } from "../../graphql/generated/graphql";
import { formatTime } from "../../i18n/formatDate";
import { formatMoney } from "../../i18n/formatNumbers";
import { PaginateNext, PaginatePrevious, PaginationContainer } from "../AdPreview/Pagination";
import { SmallLoader } from "../SmallLoader";

const limit = 10;

export const CreditTransactions = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.CreditTransactions" });
  const [page, setPage] = useState<number>(1);

  const { data, loading } = useCreditTransactionsQuery({
    variables: {
      limit,
      page,
      dateTo: new Date().toISOString().split("T")[0],
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <SmallLoader />;
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("table header.date")}</TableHeaderCell>
            <TableHeaderCell>{t("table header.transaction type")}</TableHeaderCell>
            <TableHeaderCell>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {t("table header.credit")}
                <Tooltip closeDelay={300} content={t("table credit tooltip")} delay={200} placement="bottom">
                  <IconButton icon="shp-info" ghost />
                </Tooltip>
              </div>
            </TableHeaderCell>
            {/* <TableHeaderCell className="text-right">{t("table header.spend")}</TableHeaderCell> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.organization?.creditTransactions.map((creditTransaction) => (
            <TableRow key={creditTransaction.id}>
              <TableCell>{formatTime(creditTransaction.createdAt)}</TableCell>
              <TableCell>{t(`transaction kind.${creditTransaction.kind}`)}</TableCell>
              <TableCell>
                <div
                  className="text-right"
                  style={{ color: (creditTransaction?.credit.amount || 0) < 0 ? "#DB1B4E" : "#9DCA57" }}
                >
                  {formatMoney(creditTransaction.credit, 2)}
                </div>
              </TableCell>
              {/*               <TableCell className="text-right">
                <span style={{ color: (creditTransaction?.credit.amount || 0) < 0 ? "#DB1B4E" : "#9DCA57" }}>
                  {formatMoney(creditTransaction.spend)}
                </span>
              </TableCell>
 */}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!(page === 1 && (data?.organization?.creditTransactions.length || 0) < limit) && (
        <PaginationContainer>
          <PaginatePrevious disabled={page === 1} onClick={() => setPage((state) => Math.max(state - 1, 1))} />
          <PaginateNext
            disabled={(data?.organization?.creditTransactions.length || 0) < limit}
            onClick={() => setPage((state) => state + 1)}
          />
        </PaginationContainer>
      )}
    </div>
  );
};
