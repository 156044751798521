import React from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BackIcon, NextIcon } from "../../elements/Icons";
import { InfoBox } from "../../elements/InfoBox";
import { ProgressStepper, StepsT } from "../../elements/ProgressStepper";
import { SmallLoader } from "../../elements/SmallLoader";
import { TextAssetLines } from "../../elements/TextAssetLines";
import { useGenerateTextSuggestions, useTextAssetLines } from "../../elements/TextAssetLines/useTextAssetLines";
import { SuggestionKindT, WizardStepT } from "../../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

export const TextSelection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Text Selection Page" });
  const navigate = useNavigate();
  useSetWizardStepEffect({ wizardStep: WizardStepT.TextSelectionT });
  const { activeTextAssetLines, loadingData, validateTexts } = useTextAssetLines();

  const {
    data: dataShortHeadline,
    generateMoreSuggestions: generateMoreSuggestionsShortHeadline,
    generatingMoreSuggestions: generatingMoreSuggestionsShortHeadline,
    loading: loadingShortHeadline,
  } = useGenerateTextSuggestions(SuggestionKindT.ShortHeadlineT);
  const {
    data: dataLongHeadline,
    generateMoreSuggestions: generateMoreSuggestionsLongHeadline,
    generatingMoreSuggestions: generatingMoreSuggestionsLongHeadline,
    loading: loadingLongHeadline,
  } = useGenerateTextSuggestions(SuggestionKindT.LongHeadlineT);
  const {
    data: dataShortDescription,
    generateMoreSuggestions: generateMoreSuggestionsShortDescription,
    generatingMoreSuggestions: generatingMoreSuggestionsShortDescription,
    loading: loadingShortDescription,
  } = useGenerateTextSuggestions(SuggestionKindT.ShortDescriptionT);
  const {
    data: dataLongDescription,
    generateMoreSuggestions: generateMoreSuggestionsLongDescription,
    generatingMoreSuggestions: generatingMoreSuggestionsLongDescription,
    loading: loadingLongDescription,
  } = useGenerateTextSuggestions(SuggestionKindT.LongDescriptionT);

  if (loadingShortHeadline || loadingLongHeadline || loadingShortDescription || loadingLongDescription || loadingData) {
    return (
      <div>
        <h1>{t("Headline")}</h1>
        <ProgressStepper currentStep={StepsT.STEP_TEXT} />
        <SmallLoader />
      </div>
    );
  }
  return (
    <div className="TextSelection">
      <h1>{t("Headline")}</h1>
      <ProgressStepper currentStep={StepsT.STEP_TEXT} />
      <InfoBox>{t("InfoBox")}</InfoBox>
      <TextAssetLines
        activeTextAssetLines={activeTextAssetLines || []}
        errors={validateTexts?.errors || []}
        generateMoreSuggestions={generateMoreSuggestionsLongDescription}
        generatingMoreSuggestions={generatingMoreSuggestionsLongDescription}
        kind={SuggestionKindT.LongDescriptionT}
        textSuggestions={dataLongDescription?.organization?.textSuggestions || []}
      />
      <TextAssetLines
        activeTextAssetLines={activeTextAssetLines || []}
        errors={validateTexts?.errors || []}
        generateMoreSuggestions={generateMoreSuggestionsShortDescription}
        generatingMoreSuggestions={generatingMoreSuggestionsShortDescription}
        kind={SuggestionKindT.ShortDescriptionT}
        textSuggestions={dataShortDescription?.organization?.textSuggestions || []}
      />
      <TextAssetLines
        activeTextAssetLines={activeTextAssetLines || []}
        errors={validateTexts?.errors || []}
        generateMoreSuggestions={generateMoreSuggestionsLongHeadline}
        generatingMoreSuggestions={generatingMoreSuggestionsLongHeadline}
        kind={SuggestionKindT.LongHeadlineT}
        textSuggestions={dataLongHeadline?.organization?.textSuggestions || []}
      />
      <TextAssetLines
        activeTextAssetLines={activeTextAssetLines || []}
        errors={validateTexts?.errors || []}
        generateMoreSuggestions={generateMoreSuggestionsShortHeadline}
        generatingMoreSuggestions={generatingMoreSuggestionsShortHeadline}
        kind={SuggestionKindT.ShortHeadlineT}
        textSuggestions={dataShortHeadline?.organization?.textSuggestions || []}
      />

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Button data-test-id="wizard-back" variant="secondary" onClick={() => navigate("/wizard/image-selection")}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <BackIcon />
            <span>&nbsp; {t("back")}</span>
          </span>
        </Button>
        <Button
          data-test-id="continue-to-next-step"
          disabled={!validateTexts?.valid}
          onClick={() => navigate("/wizard/check")}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            {t("continue to check")}
            &nbsp;
            <NextIcon />
          </span>
        </Button>
      </div>
    </div>
  );
};
