import React from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AssetPageHeader } from "../elements/AssetsPageHeader";
import { ImageAssetLines } from "../elements/ImageAssetLines";
import { useGenerateMoreImageSuggestions, useImageAssetLines } from "../elements/ImageAssetLines/useImageAssetLines";
import { InfoBox } from "../elements/InfoBox";
import { SmallLoader } from "../elements/SmallLoader";
import {
  AssetLineKindT,
  ImageSuggestionKindT,
  useDiscardDraftAssetLinesMutation,
  usePublishDraftAssetLinesMutation,
} from "../graphql/generated/graphql";

type EditImageAssetsPropsT = {
  kind: ImageSuggestionKindT;
};

export const EditImageAssets = ({ kind }: EditImageAssetsPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Assets Page" });
  const navigate = useNavigate();

  const { activeImageAssetLines, loadingData, validateImages } = useImageAssetLines(true);
  const { canFetchMore, data, generateMoreSuggestions, generatingMoreSuggestions, loading } =
    useGenerateMoreImageSuggestions();

  const [publish, { loading: loadingPublish }] = usePublishDraftAssetLinesMutation({
    variables: { kinds: [kind as string as AssetLineKindT] },
    onCompleted: () => navigate("/assets"),
  });
  const [discard, { loading: loadingDiscard }] = useDiscardDraftAssetLinesMutation({
    variables: { kinds: [kind as string as AssetLineKindT] },
  });

  const errors = (validateImages?.errors || []).filter((error) => (error.attribute as string) === (kind as string));
  const hasAnyDraft = !!activeImageAssetLines?.find(
    (line) => (line.kind as string) === (kind as string) && !!line.draftState
  );

  return (
    <div>
      <AssetPageHeader />
      <div style={{ height: "33px" }} />
      <InfoBox>{t("InfoBox-Do not update too often")}</InfoBox>
      <h2>{t(`asset kinds.${kind}`)}</h2>
      {loading || loadingData ? (
        <SmallLoader />
      ) : (
        <>
          <ImageAssetLines
            activeImagesAssetLines={activeImageAssetLines || []}
            canFetchMore={canFetchMore}
            errors={validateImages?.errors || []}
            generateMoreSuggestions={generateMoreSuggestions}
            generatingMoreSuggestions={generatingMoreSuggestions}
            imageSuggestions={data?.organization?.imageSuggestions || []}
            kind={kind}
            limit={5}
            draft
            hideSubtext
          />
          {hasAnyDraft ? (
            <>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <Button disabled={loadingPublish || errors.length > 0} onClick={() => publish()}>
                  {loadingPublish ? t("publishing") : t("publish changes")}
                </Button>
              </div>
              <Button disabled={loadingDiscard} variant="secondary" onClick={() => discard()}>
                {loadingDiscard ? t("reverting changes") : t("revert changes")}
              </Button>
            </>
          ) : (
            <Button disabled>{t("publish changes")}</Button>
          )}
        </>
      )}
    </div>
  );
};
