import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./shoptet-legacy.css";
import "./i18n";
import Admin from "./Admin";
import App from "./App";
import { AppSettingsProvider } from "./providers/appSettingsProvider";
import "iframe-resizer/js/iframeResizer.contentWindow";
import "@shoptet/ui/dist/index.css";

const rootElement = document.getElementById("shoptet-root") as HTMLElement;

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AppSettingsProvider>{window.location.pathname.startsWith("/admin") ? <Admin /> : <App />}</AppSettingsProvider>
  </React.StrictMode>
);
