import React from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import {
  useDeleteAssetLineMutation,
  useDisapproveSuggestionMutation,
  useRestoreDraftRemoveAssetLineMutation,
} from "../../graphql/generated/graphql";

type DeleteAssetlineActionPropsT = {
  deleteDraft?: boolean;
  draft?: boolean;
  id: string;
  width: string;
};
export const DeleteAssetLineAction = ({ deleteDraft, draft, id }: DeleteAssetlineActionPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetLineActions" });
  const [deleteAssetLine, {}] = useDeleteAssetLineMutation();

  return (
    <Button
      variant={deleteDraft ? "primary" : "destructive"}
      onClick={() => {
        deleteAssetLine({ variables: { id, draft } });
      }}
    >
      {deleteDraft ? t("Cancel creation") : t("Remove")}
    </Button>
  );
};

type RestoreDeleteDraftStateAssetlineActionPropsT = {
  id: string;
  width?: string;
};
export const RestoreDeleteDraftStateAssetlineAction = ({ id }: RestoreDeleteDraftStateAssetlineActionPropsT) => {
  const [restoreDeleteAssetLine, {}] = useRestoreDraftRemoveAssetLineMutation();
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetLineActions" });

  return (
    <Button
      variant="primary"
      onClick={() => {
        restoreDeleteAssetLine({ variables: { id } });
      }}
    >
      {t("Cancel removal")}
    </Button>
  );
};

type DisapproveSuggestionActionPropsT = {
  disabled?: boolean;
  id: string;
  kind?: string;
};
export const DisapproveSuggestionAction = ({ disabled, id, kind }: DisapproveSuggestionActionPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetLineActions" });
  const [disapprove, {}] = useDisapproveSuggestionMutation();

  return (
    <Button
      data-test-id={`${kind}-disapprove`}
      disabled={disabled}
      variant="primary"
      onClick={() => {
        disapprove({ variables: { id } });
      }}
    >
      {t("Disapprove")}
    </Button>
  );
};
