import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import { Button, SystemMessage } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { ImageSuggestionKindT, UploadedImageFragmentT, useUploadImageMutation } from "../../graphql/generated/graphql";
import { PlusIcon } from "../Icons";
import { InfoBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";
import { SmallLoader } from "../SmallLoader";
import { CropImage, IMAGE_PROPERTIES } from "./CropImage";

const MAX_FILE_SIZE = 4 * 1024 * 1024;
type UploadImagePropsT = {
  disabled?: boolean;
  draft?: boolean;
  kind: ImageSuggestionKindT;
};

type OnUploadPropsT = {
  onUpload?: () => void;
};

const ImageUploadIcon = () => (
  <svg fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35 18.6667H35.0233M29.1667 49H14C12.1435 49 10.363 48.2625 9.05025 46.9497C7.7375 45.637 7 43.8565 7 42V14C7 12.1435 7.7375 10.363 9.05025 9.05025C10.363 7.7375 12.1435 7 14 7H42C43.8565 7 45.637 7.7375 46.9497 9.05025C48.2625 10.363 49 12.1435 49 14V29.1667M7 37.3329L18.6667 25.6663C20.832 23.5826 23.5013 23.5826 25.6667 25.6663L35 34.9996M32.6667 32.6666L35 30.3332C36.5633 28.8306 38.3833 28.4106 40.0913 29.0732M37.3333 44.3333H51.3333M44.3333 37.3333V51.3333"
      stroke="#14B1EF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
  </svg>
);

export const UploadImage = ({ draft, kind, onUpload }: UploadImagePropsT & OnUploadPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.UploadImage" });
  const [isHighlighted, setHighlighted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isTooSmall, setTooSmall] = useState<string | null>(null);
  const [uploadedImage, setUploadedImage] = useState<UploadedImageFragmentT | null | undefined>(null);
  const [isTooBig, setTooBig] = useState<string | null>(null);
  const [uploadImage] = useUploadImageMutation({
    onCompleted: (response) => {
      setUploadedImage(response.uploadImage?.uploadedImage);
      setLoading(false);
    },
  });
  const ref = useRef<HTMLLabelElement>(null);

  const highlightOn = useCallback(() => setHighlighted(true), [setHighlighted]);
  const highlightOff = useCallback(() => setHighlighted(false), [setHighlighted]);

  useEffect(() => {
    const current = ref.current;
    current?.addEventListener("dragenter", highlightOn);
    current?.addEventListener("dragleave", highlightOff);

    return () => {
      current?.removeEventListener("dragenter", highlightOn);
      current?.removeEventListener("dragleave", highlightOff);
    };
  }, [ref, highlightOn, highlightOff]);

  const handleFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];
      if (file) {
        const filename = file.name;
        if (file.size > MAX_FILE_SIZE) {
          setTooBig(filename);
          setTimeout(() => setTooBig(null), 4000);
          event.target.value = "";
        } else {
          setLoading(true);
          const reader = new FileReader();
          reader.addEventListener("load", (readerEvent) => {
            const result = (readerEvent?.target?.result || "") as string;
            const [header, data] = result.split(",", 2);
            const contentType = header.replace("data:", "").replace(";base64", "");

            const img = new Image();
            img.onload = () => {
              if (img.width < IMAGE_PROPERTIES[kind].minWidth || img.height < IMAGE_PROPERTIES[kind].minHeight) {
                setTooSmall(filename);
                setTimeout(() => setTooSmall(null), 4000);
                event.target.value = "";
                setLoading(false);
              } else {
                uploadImage({
                  variables: {
                    contentType,
                    filename,
                    width: img.width || 0,
                    height: img.height || 0,
                    image: data,
                    cropX: 0,
                    cropY: 0,
                    cropWidth: 0,
                    cropHeight: 0,
                    kind,
                  },
                });
              }
              event.target.value = "";
            };
            img.src = result;
          });
          reader.readAsDataURL(file);
        }
      }
      highlightOff();
    },
    [highlightOff, uploadImage, kind]
  );

  return (
    <>
      <label
        ref={ref}
        className={isHighlighted ? "dragover" : ""}
        style={{
          marginTop: "30px",
          border: "1px solid #E9E9E9",
          position: "relative",
          width: "100%",
          display: "block",
          padding: "20px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            color: "#37373D",
            background: "white",
            padding: "0px 10px",
          }}
        >
          {t("Container headline")}
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {isLoading ? (
            <div className="UploadImage-loader">
              <SmallLoader />
            </div>
          ) : (
            <ImageUploadIcon />
          )}
          {!isLoading && isHighlighted && (
            <div style={{ marginTop: "10px" }}>
              <Button variant="action">
                <span style={{ display: "flex", alignItems: "center" }}>
                  <PlusIcon /> &nbsp; {t("drop file here text")}
                </span>
              </Button>
            </div>
          )}
          {!isLoading && !isHighlighted && (
            <div style={{ marginTop: "10px" }}>
              <Button variant="action">
                <span style={{ display: "flex", alignItems: "center" }}>
                  <PlusIcon /> &nbsp; {t("button text")}
                </span>
              </Button>
            </div>
          )}
        </div>
        <input
          accept="image/png, image/jpeg"
          className="UploadImage-input"
          style={{}}
          type="file"
          onChange={handleFile}
        />
        {uploadedImage && (
          <CropImage
            draft={draft}
            uploadedImage={uploadedImage}
            onClose={() => {
              setUploadedImage(null);
              if (typeof onUpload === "function") {
                onUpload();
              }
            }}
          />
        )}
      </label>
      {!isLoading && isTooBig && (
        <SystemMessage description={t("file filename is too big", { filename: isTooBig })} level="error" />
      )}
      {!isLoading && isTooSmall && (
        <SystemMessage description={t("file filename is too small", { filename: isTooSmall })} level="error" />
      )}
    </>
  );
};

export const UploadImageWithModal = ({ disabled, ...props }: UploadImagePropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.UploadImage" });
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <Button disabled={disabled} variant="secondary" onClick={() => setModalOpened(true)}>
        {t("upload image button")}
      </Button>
      <ModalWrapper isOpen={modalOpened} title={t("modal headline")} onClose={() => setModalOpened(false)}>
        <InfoBox>
          <strong>{t("info box text")}</strong>
          <ul style={{ marginBottom: 0 }}>
            <li>
              {props.kind === ImageSuggestionKindT.SquareMarketingImageT ? t("info box list 1") : t("info box list 2")}
            </li>
            <li>{t("info box list 3")}</li>
            <li>{t("info box list 4")}</li>
          </ul>
        </InfoBox>
        <UploadImage {...props} onUpload={() => setModalOpened(false)} />
      </ModalWrapper>
    </>
  );
};
