import React, { useEffect, useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BackIcon, ChevronDownIcon, ChevronUpIcon, Icon, NextIcon } from "../../elements/Icons";
import { ImageAssetLines } from "../../elements/ImageAssetLines";
import { InfoBanner } from "../../elements/ImageAssetLines/InfoBanner";
import { useGenerateMoreImageSuggestions, useImageAssetLines } from "../../elements/ImageAssetLines/useImageAssetLines";
import { InfoBox } from "../../elements/InfoBox";
import { ProgressStepper, StepsT } from "../../elements/ProgressStepper";
import { SmallLoader } from "../../elements/SmallLoader";
import { ImageSuggestionKindT, WizardStepT } from "../../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

export const ImageSelection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Image Selection Page" });
  useSetWizardStepEffect({ wizardStep: WizardStepT.ImageSelectionT });
  const [startGenerating, setStartGenerating] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [showLandscape, setShowLandscape] = useState(false);
  const [showSquare, setShowSquare] = useState(true);
  const navigate = useNavigate();

  const { activeImageAssetLines, loadingData, validateImages } = useImageAssetLines();
  const { canFetchMore, data, generateMoreSuggestions, generatingMoreSuggestions, loading } =
    useGenerateMoreImageSuggestions();

  useEffect(() => {
    if (activeImageAssetLines?.length !== 0) {
      setInitiated(true);
    }
    if (
      !initiated &&
      !showLandscape &&
      (activeImageAssetLines || []).filter(
        (assetLine) => (assetLine.kind as string) === (ImageSuggestionKindT.MarketingImageT as string)
      ).length > 0
    ) {
      setShowLandscape(true);
    }
  }, [initiated, setInitiated, showLandscape, activeImageAssetLines, setShowLandscape]);

  const canContinueToLandscape =
    (activeImageAssetLines || []).filter(
      (assetLine) => (assetLine.kind as string) === (ImageSuggestionKindT.SquareMarketingImageT as string)
    ).length > 0;

  if (loading || loadingData) {
    return (
      <div>
        <h1>{t("Headline")}</h1>
        <ProgressStepper currentStep={StepsT.STEP_IMAGE} />
        <SmallLoader />
      </div>
    );
  }

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <ProgressStepper currentStep={StepsT.STEP_IMAGE} />
      <InfoBox>{t("InfoBox")}</InfoBox>
      <InfoBanner />
      {startGenerating && (
        <div style={{ height: "90ch", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ textAlign: "center" }}>
            <Icon kind="loader" />
            <br />
            {t("generation started text")}
          </div>
        </div>
      )}
      <div style={{ display: startGenerating ? "none" : "block" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {showLandscape && <Icon kind="accomplish-item" />}
          <h2 style={{ flexGrow: 1, margin: 0 }}>{t("Square Image Headline")}</h2>
          {showLandscape && (
            <span style={{ marginLeft: "30px", cursor: "pointer" }} onClick={() => setShowSquare(!showSquare)}>
              {showSquare ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </span>
          )}
        </div>
        <div style={{ display: showSquare ? "block" : "none" }}>
          <ImageAssetLines
            activeImagesAssetLines={activeImageAssetLines || []}
            canFetchMore={canFetchMore}
            errors={validateImages?.errors || []}
            generateMoreSuggestions={generateMoreSuggestions}
            generatingMoreSuggestions={generatingMoreSuggestions}
            imageSuggestions={data?.organization?.imageSuggestions || []}
            kind={ImageSuggestionKindT.SquareMarketingImageT}
            limit={5}
          />
        </div>
        {showLandscape && (
          <>
            <h2>{t("Landscape Image Headline")}</h2>
            <ImageAssetLines
              activeImagesAssetLines={activeImageAssetLines || []}
              canFetchMore={canFetchMore}
              errors={validateImages?.errors || []}
              generateMoreSuggestions={generateMoreSuggestions}
              generatingMoreSuggestions={generatingMoreSuggestions}
              imageSuggestions={data?.organization?.imageSuggestions || []}
              kind={ImageSuggestionKindT.MarketingImageT}
              limit={5}
            />
          </>
        )}
        {showLandscape ? (
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <Button
              data-test-id="wizard-back"
              variant="secondary"
              onClick={() => {
                setShowSquare(true);
                setShowLandscape(false);
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <BackIcon />
                <span>&nbsp; {t("back")}</span>
              </span>
            </Button>
            <Button
              data-test-id="continue-to-next-step"
              disabled={!validateImages?.valid}
              onClick={() => navigate("/wizard/text-selection")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {t("continue to texts")}
                &nbsp;
                <NextIcon />
              </span>
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <Button
              data-test-id="wizard-back"
              variant="secondary"
              onClick={() => navigate("/wizard/suggestion-generator")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <BackIcon />
                <span>&nbsp; {t("back")}</span>
              </span>
            </Button>
            <Button
              data-test-id="continue-to-next-step"
              disabled={!canContinueToLandscape}
              onClick={() => {
                setShowSquare(false);
                setShowLandscape(true);
                setStartGenerating(true);
                setTimeout(() => setStartGenerating(false), 1000);
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {t("continue to landscape banners")}
                &nbsp;
                <NextIcon />
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
