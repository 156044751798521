import React from "react";
import { Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdPreview } from "../elements/AdPreview";
import { AssetPageHeader } from "../elements/AssetsPageHeader";
import { InfoBox } from "../elements/InfoBox";
import { SmallLoader } from "../elements/SmallLoader";
import {
  AssetLineKindT,
  ImageSuggestionKindT,
  SuggestionKindT,
  useActiveImagesAssetLinesQuery,
  useActiveTextsAssetLinesQuery,
  useNotDeletedImagesAssetLinesQuery,
  useNotDeletedTextsAssetLinesQuery,
  useOrganizationDataQuery,
  useRemoveDisapprovedAssetLinesMutation,
} from "../graphql/generated/graphql";

const TEXTS = [
  SuggestionKindT.ShortHeadlineT,
  SuggestionKindT.LongHeadlineT,
  SuggestionKindT.ShortDescriptionT,
  SuggestionKindT.LongDescriptionT,
];

const IMAGES = [ImageSuggestionKindT.SquareMarketingImageT, ImageSuggestionKindT.MarketingImageT];

export const Assets = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Assets Page" });
  const navigate = useNavigate();
  const [removeDisapproved, { loading: loadingRemoveDisapproved }] = useRemoveDisapprovedAssetLinesMutation();
  const { data: organizationData, loading: loadingProductsAndDesign } = useOrganizationDataQuery();
  const { data: dataActiveImageAssetLines, loading: loadingImages } = useActiveImagesAssetLinesQuery();
  const { data: dataNotDeletedImageAssetLines } = useNotDeletedImagesAssetLinesQuery();
  const { data: dataActiveTextAssetLines, loading: loadingTexts } = useActiveTextsAssetLinesQuery();
  const { data: dataNotDeletedTextAssetLines } = useNotDeletedTextsAssetLinesQuery();

  const hasAnyDrafts = !!(
    (dataNotDeletedImageAssetLines?.organization?.notDeletedImagesAssetLines || []).find((line) => line.draftState) ||
    (dataNotDeletedTextAssetLines?.organization?.notDeletedTextsAssetLines || []).find((line) => line.draftState)
  );

  return (
    <div>
      <AssetPageHeader />
      <h2>{t("Table Headline")}</h2>
      <InfoBox>{t("InfoBox")}</InfoBox>
      <br />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("table header.asset kind")}</TableHeaderCell>
            <TableHeaderCell>
              <div className="text-center">{t("table header.valid assets")}</div>
            </TableHeaderCell>
            <TableHeaderCell>
              <div className="text-center">{t("table header.rejected assets")}</div>
            </TableHeaderCell>
            {hasAnyDrafts && (
              <TableHeaderCell>
                <div className="text-center">{t("table header.unpublished changes")}</div>
              </TableHeaderCell>
            )}
            <TableHeaderCell>
              <div style={{ display: "flex", justifyContent: "end" }}>{t("table header.action")}</div>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {IMAGES.map((kind) => {
            const lines = dataActiveImageAssetLines?.organization?.activeImagesAssetLines.filter(
              (line) => (kind as string) === (line.kind as string)
            );
            const disapprovedLines = lines?.filter((line) => line.googleDisapproved);
            const draftLines =
              dataNotDeletedImageAssetLines?.organization?.notDeletedImagesAssetLines?.filter(
                (line) => (kind as string) === (line.kind as string) && line.draftState
              ) || [];
            return (
              <TableRow key={kind} data-test-id={`table-row-${kind}`}>
                <TableCell>{t(`asset kinds.${kind}`)}</TableCell>
                <TableCell>
                  <div className="text-center" data-test-id="active-count">
                    {loadingImages ? "" : lines?.length}
                  </div>
                </TableCell>
                <TableCell>
                  {loadingImages || disapprovedLines?.length === 0 ? (
                    ""
                  ) : (
                    <div className="text-center" data-test-id="disapproved-count" style={{ color: "red" }}>
                      {disapprovedLines?.length}
                    </div>
                  )}
                </TableCell>
                {hasAnyDrafts && (
                  <TableCell>
                    <div className="text-center" style={{ color: "#9DCA57" }}>
                      {draftLines.length > 0 ? draftLines.length : ""}
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {disapprovedLines && disapprovedLines.length > 0 && (
                      <div style={{ marginRight: "10px" }}>
                        <Button
                          data-test-id="replace-bad-assets"
                          disabled={loadingRemoveDisapproved}
                          onClick={() =>
                            removeDisapproved({
                              variables: { kinds: [kind as string as AssetLineKindT], draft: true },
                            }).then(() => navigate(`/assets/${kind}`))
                          }
                        >
                          {t("replace rejected assets button")}
                        </Button>
                      </div>
                    )}
                    <Button
                      data-test-id="add-new-assets"
                      variant="secondary"
                      onClick={() => navigate(`/assets/${kind}`)}
                    >
                      {t("add asset button")}
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {TEXTS.map((kind) => {
            const lines = dataActiveTextAssetLines?.organization?.activeTextsAssetLines.filter(
              (line) => (kind as string) === (line.kind as string)
            );
            const disapprovedLines = lines?.filter((line) => line.googleDisapproved);
            const draftLines =
              dataNotDeletedTextAssetLines?.organization?.notDeletedTextsAssetLines?.filter(
                (line) => (kind as string) === (line.kind as string) && line.draftState
              ) || [];
            return (
              <TableRow key={kind} data-test-id={`table-row-${kind}`}>
                <TableCell>{t(`asset kinds.${kind}`)}</TableCell>
                <TableCell>
                  <div className="text-center" data-test-id="active-count">
                    {loadingTexts ? "" : lines?.length}
                  </div>
                </TableCell>
                <TableCell>
                  {loadingTexts || disapprovedLines?.length === 0 ? (
                    ""
                  ) : (
                    <div className="text-center" data-test-id="disapproved-count" style={{ color: "red" }}>
                      {disapprovedLines?.length}
                    </div>
                  )}
                </TableCell>
                {hasAnyDrafts && (
                  <TableCell>
                    <div className="text-center" style={{ color: "#9DCA57" }}>
                      {draftLines.length > 0 ? draftLines.length : ""}
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {disapprovedLines && disapprovedLines.length > 0 && (
                      <div style={{ marginRight: "10px" }}>
                        <Button
                          data-test-id="replace-bad-assets"
                          disabled={loadingRemoveDisapproved}
                          onClick={() =>
                            removeDisapproved({
                              variables: { kinds: [kind as string as AssetLineKindT], draft: true },
                            }).then(() => navigate(`/assets/${kind}`))
                          }
                        >
                          {t("replace rejected assets button")}
                        </Button>
                      </div>
                    )}
                    <Button
                      data-test-id="add-new-assets"
                      variant="secondary"
                      onClick={() => navigate(`/assets/${kind}`)}
                    >
                      {t("add asset button")}
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div style={{ background: "#F8F8F8", padding: "10px", margin: "20px 0" }}>
        <h2>{t("Preview Headline")}</h2>
        {loadingImages || loadingTexts || loadingProductsAndDesign ? (
          <SmallLoader />
        ) : (
          <AdPreview
            activeImagesAssetLines={dataActiveImageAssetLines?.organization?.activeImagesAssetLines || []}
            activeTextAssetLines={dataActiveTextAssetLines?.organization?.activeTextsAssetLines || []}
            organization={organizationData?.organization}
          />
        )}
      </div>
    </div>
  );
};
