import React, { ReactNode, useCallback, useState } from "react";
import { Button, IconButton, SelectField, SystemMessage, Tabs, Tooltip } from "@shoptet/ui";
import { flatten, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import {
  OrganizationCategoriesQueryT,
  ProductExclusionQueryT,
  useExcludeCategoriesMutation,
  useExcludeProductFlagsMutation,
  useProductExclusionQuery,
} from "../../graphql/generated/graphql";
import { CategoryTree } from "../CategoriesSelection";
import { CheckmarkIcon } from "../Icons";
import { SmallLoader } from "../SmallLoader";

const calculatePercent = (count: number, total: number) => (total > 0 ? (count / total) * 100 : 0);

type ExcludeBoxPropsT = {
  count: number;
  info?: string;
  summary?: boolean;
  text: string | ReactNode;
  total: number;
};
const ExcludeBox = ({ count, info, summary, text, total, ...rest }: ExcludeBoxPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdvancedSetup" });
  return (
    <div
      {...rest}
      style={{
        flex: 1,
        minWidth: "150px",
        display: "flex",
        flexDirection: "column",
        textAlign: summary ? "center" : "left",
        border: summary ? "" : "1px solid #E9E9E9",
        padding: "16px",
      }}
    >
      <div style={{ flexGrow: 1, color: "#37373D", fontSize: "12px", fontWeight: summary ? 700 : 400 }}>{text}</div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "4px" }}>
        <div style={{ flexGrow: 1 }}>
          <div>
            <span
              style={{ color: "#14B1EF", fontSize: summary ? "24px" : "18px", fontWeight: 700, paddingRight: "5px" }}
            >
              {Math.round(calculatePercent(count, total))}%
            </span>
            <span style={{ color: "#14B1EF", fontSize: "12px" }}>({count})</span>
          </div>
          <div style={{ color: "#14B1EF", fontSize: "12px" }}>{t("from all products")}</div>
        </div>
        {info && (
          <Tooltip content={info}>
            <IconButton icon="shp-info" ghost />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

type ExcludeByFlagPropsT = {
  loading: boolean;
  organization?: NonNullable<ProductExclusionQueryT["organization"]>;
  selectId: (v: string) => void;
  selectedId: string;
};
const ExcludeByFlag = ({ loading, organization, selectId, selectedId }: ExcludeByFlagPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdvancedSetup" });
  const [isSaved, setIsSaved] = useState(false);
  const currentSelectedId = (organization?.productFlags || []).find(({ excluded }) => excluded)?.id || "";

  const [excludeProductFlags, { error: mutationError, loading: mutationLoading }] = useExcludeProductFlagsMutation({
    onCompleted: () => {
      // toast.success("Uloženo vyloučení produktů");
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000);
    },
  });

  if (loading || !organization) {
    return <SmallLoader />;
  }

  const options = organization.productFlags.reduce(
    (acc, { id, name, productsCount }) => ({ ...acc, [id]: `${name} (${productsCount} produktů)` }),
    { "": "" }
  );

  const { productFlags, selectableProductsCount } = organization;
  const selectedFlagProductsCount =
    productFlags.find(({ excluded, id }) => id === selectedId && !excluded)?.productsCount || 0;
  const lastProductsToExclude = selectableProductsCount - selectedFlagProductsCount < 1;

  return (
    <div className="v2form">
      <p>{t("Exclude by product flags Text")}</p>
      {mutationError && <SystemMessage description={t("there was a problem saving excluded flags")} level="error" />}
      <div className="addonWrapper" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <SelectField
          disabled={mutationLoading || isSaved}
          label={t("Exclude products label")}
          name="exclude_flag"
          options={options}
          value={selectedId}
          onChange={(event) => {
            selectId(event.target.value);
          }}
        />
        {(currentSelectedId === selectedId && !mutationLoading && !isSaved && <span />) || (
          <span className="addon">
            <Button
              data-test-id="save-excluded-products"
              disabled={mutationLoading || isSaved || lastProductsToExclude}
              variant="action"
              onClick={() =>
                excludeProductFlags({
                  variables: { productFlagIds: selectedId.length > 0 ? [selectedId] : [] },
                })
              }
            >
              {(isSaved && (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <CheckmarkIcon /> &nbsp; {t("Saved")}
                </span>
              )) ||
                (mutationLoading && t("Saving")) ||
                t("Save")}
            </Button>
          </span>
        )}
      </div>
      {lastProductsToExclude && <SystemMessage description={t("You cannot exclude all products")} level="error" />}
    </div>
  );
};

type ExcludeCategoriesSelectionPropsT = {
  categories?: NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"];
  disabled?: boolean;
};

const returnCategoryTreeIds = (
  parentId: string,
  categories?: NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"]
): string[] => {
  const cat = (categories || []).find((category) => category.id === parentId);
  if (!cat) {
    return [parentId];
  }
  return flatten([
    parentId,
    ...(categories || [])
      .filter((category) => category.parentGuid === cat.guid)
      .map((category) => returnCategoryTreeIds(category.id, categories)),
  ]);
};

const ExcludeCategoriesSelection = ({ categories, disabled }: ExcludeCategoriesSelectionPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  const [isSaved, setIsSaved] = useState(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    (categories || []).filter((category) => category.excluded).map(({ id }) => id)
  );
  const currentSelectedIds = (categories || []).filter((category) => category.excluded).map(({ id }) => id);

  const [selectCategories, { error: mutationError, loading: mutationLoading }] = useExcludeCategoriesMutation({
    variables: { categoryIds: selectedCategoryIds },
    onCompleted: () => {
      // toast.success("Vybrané kategorie uloženy");
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000);
    },
  });

  const addCategory = useCallback(
    (id: string) => {
      const newState = uniq([...selectedCategoryIds, ...returnCategoryTreeIds(id, categories)]);
      setSelectedCategoryIds(newState);
    },
    [setSelectedCategoryIds, selectedCategoryIds, categories]
  );
  const removeCategory = useCallback(
    (id: string) => {
      const removeIds = returnCategoryTreeIds(id, categories);
      const newState = [...selectedCategoryIds].filter((catId) => removeIds.indexOf(catId) === -1);
      setSelectedCategoryIds(newState);
    },
    [setSelectedCategoryIds, selectedCategoryIds, categories]
  );

  const subCategories = (categories || []).filter((category) => category.parentGuid !== null);
  const allSubCategoriesSelected =
    !!subCategories.length && subCategories.every((category) => selectedCategoryIds.includes(category.id));
  const selectedAllCategories = selectedCategoryIds.length === categories?.length;
  return (
    <>
      <p>{t("Exclude by categories Text")}</p>
      <div style={{ border: "1px solid #E9E9E9", margin: "20px 0", padding: "10px", background: "white" }}>
        <CategoryTree
          addCategory={addCategory}
          categories={categories}
          disabled={disabled}
          kind="exclude"
          removeCategory={removeCategory}
          selectedCategoryIds={selectedCategoryIds}
        />
      </div>
      <div
        data-test-id="category-exclusion-result"
        style={{ border: "1px solid #E9E9E9", padding: "24px", textAlign: "center", color: "#37373D" }}
      >
        {t("excluded")}{" "}
        <strong>
          {selectedCategoryIds.length} {t("categories")}
        </strong>
      </div>
      {(selectedAllCategories || allSubCategoriesSelected) && (
        <SystemMessage description={t("You cannot exclude all categories")} level="error" />
      )}
      {(JSON.stringify(currentSelectedIds.sort()) === JSON.stringify(selectedCategoryIds.sort()) &&
        !mutationLoading &&
        !isSaved && <span />) || (
        <div style={{ marginTop: "10px" }}>
          <Button
            data-test-id="save-category-exclusion"
            disabled={selectedAllCategories || allSubCategoriesSelected}
            variant="action"
            onClick={() => selectCategories()}
          >
            {(isSaved && (
              <span style={{ display: "flex", alignItems: "center" }}>
                <CheckmarkIcon /> &nbsp; {t("Saved")}
              </span>
            )) ||
              (mutationLoading && t("Saving")) ||
              t("Save")}
          </Button>
        </div>
      )}
      {mutationError && (
        <SystemMessage description={t("there was a problem saving category selection")} level="error" />
      )}
    </>
  );
};

export const ExcludeProducts = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdvancedSetup" });
  const [selectedId, selectId] = useState("");
  const [page, setPage] = useState("exclude-product-flags");
  const { data, loading } = useProductExclusionQuery({
    onCompleted(dataResponse) {
      selectId(dataResponse.organization?.productFlags.find(({ excluded }) => excluded)?.id || "");
    },
  });

  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  const total = data.organization.productsCount;

  const tabs = [
    {
      id: "exclude-product-flags",
      title: t("Filter by product flags"),
      content: (
        <ExcludeByFlag
          loading={loading}
          organization={data?.organization}
          selectedId={selectedId}
          selectId={selectId}
        />
      ),
    },
    {
      id: "exclude-categories",
      title: t("Filter by categories"),
      content: <ExcludeCategoriesSelection categories={data.organization.categories} />,
    },
  ];

  return (
    <div>
      <Tabs activeTab={page} setActiveTab={setPage} tabs={tabs} />

      <div
        style={{ border: "1px solid #E9E9E9", padding: "0px 16px 16px 16px", margin: "20px 0", textAlign: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ExcludeBox
            count={data.organization.excludedProductsCount}
            data-test-id="excluded-products-total"
            text={t("You have excluded")}
            total={total}
            summary
          />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: "16px", width: "100%" }}>
          {data.organization.excludedProductFlagsProductsCount > 0 && (
            <ExcludeBox
              count={data.organization.excludedProductFlagsProductsCount}
              data-test-id="excluded-products-by-product-flags"
              text={t("You have excluded by product flags")}
              total={total}
            />
          )}
          {data.organization.excludedCategoriesProductsCount > 0 && (
            <ExcludeBox
              count={data.organization.excludedCategoriesProductsCount}
              data-test-id="excluded-products-by-categories"
              text={t("You have excluded by categories")}
              total={total}
            />
          )}
          {data.organization.bannedProductsCount > 0 && (
            <ExcludeBox
              count={data.organization.bannedProductsCount}
              data-test-id="excluded-products-banned"
              info={t("Banned info tooltip")}
              text={t("We have excluded banned")}
              total={total}
            />
          )}
          {data.organization.outOfStockProductsCount > 0 && (
            <ExcludeBox
              count={data.organization.outOfStockProductsCount}
              data-test-id="excluded-products-out-of-stock"
              text={t("We have excluded out of stock")}
              total={total}
            />
          )}
        </div>
      </div>
    </div>
  );
};
