import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useOrganizationMonthlyBudgetQuery } from "../../graphql/generated/graphql";
import { formatCurrencyUnit, formatMoney } from "../../i18n/formatNumbers";
import NumberFieldWithUnit from "../NumberFieldWithUnit";
import { SmallLoader } from "../SmallLoader";
import { MonthlyBudget } from ".";

export const UpdateMonthlyBudget = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.MonthlyBudget" });
  const { data: dataMonthlyBudget, loading: loadingBudget } = useOrganizationMonthlyBudgetQuery();
  const [edditing, setEditting] = useState(false);
  if (loadingBudget) {
    return (
      <div>
        <SmallLoader />
      </div>
    );
  }
  return edditing ? (
    <MonthlyBudget afterEditButton onComplete={() => setEditting(false)} />
  ) : (
    <div
      className="v2form"
      style={{ display: "flex", justifyItems: "start", alignItems: "center", flexDirection: "row" }}
    >
      <NumberFieldWithUnit
        label={t("Label")}
        name="current_monthly_budget"
        tooltip={t("Tooltip")}
        unit={formatCurrencyUnit(dataMonthlyBudget?.organization?.monthlyBudget?.currency || "CZK")}
        value={formatMoney({ amount: dataMonthlyBudget?.organization?.monthlyBudget?.amount || 0 })}
        addon={
          <div className="edit-btn-addon" style={{ marginRight: "10px" }}>
            <Button data-test-id="update-monthly-budget-button" onClick={() => setEditting(true)}>
              {t("Edit button")}
            </Button>
          </div>
        }
        disabled
      />
    </div>
  );
};
